<template>
    <div>
   <br><br>
    <center><h2>Buy Credits</h2></center>
    <br><br>
    <v-container >

        <v-layout row wrap align-center >                      
            <v-flex  v-for="item in items" :key="item.id" xs6 md3 >
                <div class="text-center">

                        <h2 class="bebas_35">
                            {{item.name}}</h2>    
                        <h3>{{item.baht}}</h3>
                        <h3>{{item.descr}}</h3><br>
                        <h5 class="font-weight-light">{{item.expire}}</h5>
                        <br>
                        <v-btn class="font-weight-bold mt-5" tile color="black" dark 
                            @click="buynow( item.url )" >Buy Now</v-btn>
                        <br><br><br><br>
                </div>                
            </v-flex>
        </v-layout>
    </v-container>

    </div>
</template>

<script>
export default {
    name: "buycredit_comp",
    components:{

    },
  data() {
    return {
        items: [
            {
                name : '6 MEALS',
                descr :'(฿260/meal)',
                baht :'฿1,560',
                expire :'7 DAYS EXPIRY DATE',
                url : 'https://api.plantifulbkk.shop/pgk_pay.php?pkg=3&scode='+this.$store.getters.get_user_code,
            },
            {
                name : '10 MEALS',
                descr :'(฿240/meal)',
                baht :'฿2,400',
                expire :'14 DAYS EXPIRY DATE',
                url : 'https://api.plantifulbkk.shop/pgk_pay.php?pkg=5&scode='+this.$store.getters.get_user_code,
            },
            {
                name : '20 MEALS',
                descr :'(฿220/meal)',
                baht :'฿4,400',
                expire :'30 DAYS EXPIRY DATE',
                url : 'https://api.plantifulbkk.shop/pgk_pay.php?pkg=10&scode='+this.$store.getters.get_user_code,
            },
            {
                name : '40 MEALS',
                descr :'(฿200/meal)',
                baht :'฿8,000',
                expire :'60 DAYS EXPIRY DATE',
                url : 'https://api.plantifulbkk.shop/pgk_pay.php?pkg=20&scode='+this.$store.getters.get_user_code,
            },                        
        ],
    }
  },

  methods :{
      buynow( url ){
        //alert(" *coming soon* ")
        //console.log( url)
        window.location.href = url
      },

    }
  }
</script>

<style>

    @font-face {
    font-family: bebas ;
    src: url("../assets/font/BebasNeue Bold.ttf") ;
    }
    .bebas_35{
    font-family: bebas;
    font-size:35px;
    }
    .bebas_25{
    font-family: bebas;
    font-size:25px;
    }
    .bebas_17{
    font-family: bebas;
    font-size:17px;
    }
    .bebas_18{
    font-family: bebas;
    font-size:18px;
    }
    .bebas_15{
    font-family: bebas;
    font-size:15px;
    }
</style>