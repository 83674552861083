<template>
    <div>
   <br><br>
    <center><h2>Forgot Password</h2></center>
    <v-container grid-list-xs>
        <center>If you have forgotten your password you can reset it here.</center>
        <v-layout justify-center> 
            <v-flex xs6>
                <v-form ref="form" v-model="valid" lazy-validation>  
                <v-text-field
                    name="Username"
                    label="Email"
                    id="username"
                    v-model="email"
                    type="text"
                    color="black" 
                    required></v-text-field>
                <center>
                    <v-btn class="font-weight-light" tile  :disabled="!valid"
                        @click="send_mail">
                        submit
                    </v-btn>
                </center>    
                </v-form>
            </v-flex>
        </v-layout>   
    </v-container>
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "forgot_comp",
    components:{

    },
  data() {
    return {
        email: '',
    }
  },
  methods :{
      send_mail(){
            axios.get("https://ws.plantifulbkk.shop/send_mail_forgot_pass.php",{
                params: {
                    to_mail: this.email ,
                }
            }).then(result=>{
                console.log(result.data)
                this.$refs.form.reset()
                if ( result.data !== '' ){
                    alert("Send email successfully. Please check your email. !!")
                    this.$router.push('/')
                }else{
                    alert("Cannot Find your email. Please try again. !!")
                }   
            }).catch(function (error) {
                alert("Error : Cannot send email !!")
                console.log(error)
        })
      },
    }
  }
</script>