<template>
    <div>
        <buycredit/>
    </div>
</template>

<script>
import buycredit from "@/components/buy_credit.vue";

export default {

  name: 'Buy_Credits_view',
  components: {
    buycredit,
  }
}
</script>