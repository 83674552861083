import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login'
import register from '@/views/register'
import forgot from '@/views/forgot'
import main from '@/views/main'
import order from '@/views/order'
import orders from '@/views/order_s'
import history1 from '@/views/history1'
import custinfo from '@/views/custinfo'
import cngpass from '@/views/cng_password'
import confirm from '@/views/confirm'
import buycredit from '@/views/buy_credit'
import calendar from '@/views/calendar_page'
import select_reg from '@/views/select_reg'
import main1 from '@/views/main_1'

Vue.use(VueRouter)


/*
export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: forgot
    },    
    {
      path: '/main',
      name: 'main',
      component: main
    },
    {
      path: '/order',
      name: 'order',
      component: order
    },
    {
      path: '/buycredit',
      name: 'buycredit',
      component: buycredit
    },    
    {
      path: '/orders',
      name: 'orders',
      component: orders
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: confirm
    },
    {
      path: '/history1',
      name: 'history1',
      component: history1
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: calendar
    },    
    {
      path: '/custinfo',
      name: 'custinfo',
      component: custinfo
    },
    {
      path: '/cngpass',
      name: 'cngpass',
      component: cngpass
    },
]
});

*/


  const routes = [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: forgot
    },    
    {
      path: '/main',
      name: 'main',
      component: main
    },
    {
      path: '/main1',
      name: 'main1',
      component: main1
    },
    {
      path: '/order',
      name: 'order',
      component: order
    },
    {
      path: '/buycredit',
      name: 'buycredit',
      component: buycredit
    },    
    {
      path: '/orders',
      name: 'orders',
      component: orders
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: confirm
    },
    {
      path: '/history1',
      name: 'history1',
      component: history1
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: calendar
    },    
    {
      path: '/custinfo',
      name: 'custinfo',
      component: custinfo
    },
    {
      path: '/cngpass',
      name: 'cngpass',
      component: cngpass
    },
    {
      path: '/select_reg',
      name: 'select_reg',
      component: select_reg
    },
]



const router = new VueRouter({
  routes
})

export default router