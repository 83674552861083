<template>
    <div>
        <forgot/>
    </div>
</template>

<script>
import forgot from "@/components/forgot.vue";

export default {

  name: 'Forget_Password_view',
  components: {
    forgot,
  }
}
</script>