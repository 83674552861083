import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import lineClamp from 'vue-line-clamp'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Toasted from 'vue-toasted';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueSession from 'vue-session'
import VueCookie from 'vue-cookie'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(lineClamp, {
  // plugin options
})
Vue.use(Toasted)
Vue.use(VueSession)
Vue.use(VueCookie);
Vue.use(VueMoment, {
  moment,
})

new Vue({
  components: {
    PulseLoader
  }
})
