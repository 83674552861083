<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      light
      height="90px"
    >

      <v-layout row wrap >  
        <v-img  class="ml-0 ml-md-10"
          src="@/assets/images/Plantiful Logo.png" max-width="150px" v-on:click="go_home()"
        ></v-img>
      

      <v-spacer></v-spacer>

      <div  class="mr-5 mr-md-12 mt-1" >

              <v-icon v-if="userIsAuthenticated &&  this.$store.getters.get_show_cart>0 " color="#3AB881"  
              class="mr-0" size="42" @click="show_cart()">shopping_cart</v-icon>
              <span class='badge badge-warning' id='lblCartCount'
                v-if="this.$store.getters.get_use_point>0 &&  this.$store.getters.get_show_cart>0 && userIsAuthenticated" > {{this.$store.getters.get_use_point}} </span>

              <v-menu  offset-y  v-if="userIsAuthenticated" :rounded='0'>
                <template v-slot:activator ="{ on, attrs }">
                  <v-avatar class="ml-3" color="#3AB881"  size="48" v-bind="attrs" v-on="on">
                        <img 
                          :src=$store.getters.get_user_img 
                        >
                  </v-avatar>

                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="menu(index)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn tile v-if="userIsGuest"  color="white" @click="resg()"  >
                Sign in
              </v-btn>    

      </div>

      </v-layout>

    </v-app-bar>
    <v-content>
      <router-view></router-view>

      <cart ref="show_cart" />

    </v-content>
  </v-app>

</template>

<script>
import axios from "axios"
import cart from "@/components/cart"
import liff from "@line/liff";
//import mainorder from "@/components/main_order"

export default {
  name: 'App',

  components: {
    cart,
    //mainorder,
  },
  methods: {

    onSignin_sus () {

      console.log( 'onSignin_scode_storage = ' + sessionStorage.getItem('scode_storage') )

        axios.get("https://ws.plantifulbkk.shop/login_ses.php",{
            params: {
                //scode: this.$session.get('scode_ses') ,                
                scode: sessionStorage.getItem('scode_storage'),
            }
        }).then(result=>{
                console.log(result.data)
                let res = result.data
                if ( result.data.includes("incorrect") ){
                  console.log( 'onSignin_sus - incorrect' )
                  alert("Username or Password Incorrect !!")

                }else{

                      let res3 = res.split("^");
                      
                      this.event_id = this.make_event_id(5)

                      axios.get("https://ws.plantifulbkk.shop/post_event_log_g.php",{
                                  params: {
                                    scode: res3[3] ,
                                    event_id : this.event_id ,
                                    SESSION : '',
                                    log : 'start..login.by.email' ,
                                  }
                              }).then(result=>{

                                console.log(result)
                      }) 

                    this.$store.dispatch('set_user_ac',res3[0])
                    this.$store.dispatch('your_exp_allergies',res3[1])
                    this.$store.dispatch('your_exp_remark',res3[2])
                    this.$store.dispatch('set_user_ac_code', res3[3])
                    this.$store.dispatch('set_user_img', res3[4]  )
                    this.$store.dispatch('set_create_user_ac', 'N'  )
                    this.$router.replace("main");
                }
            })
        .catch(function (error) {
                alert("Error")
                console.log(error)
            })

        },

    signout() {
      this.$store.dispatch('set_user_ac','')
      liff.logout()
      window.location.href = "https://th.plantifulbkk.com/"
    },
    menu( inx ){

      switch(inx) {
        case 0:
             this.$router.push('/custinfo')
          break;
        case 1:
             this.$router.push('/cngpass')
          break;    
        case 2:
             this.$router.push('/buycredit')
          break;                    
        case 3:
             this.$router.push('/main')
          break;
        case 4:
            this.$router.push('/calendar')
          break;
        case 5:
            this.$router.push('/history1')
          break;          
        case 6:
            this.signout()
          break;  
      }

    },
      go_home(){
        this.$router.push('/main')
      },
      resg(){
        this.$router.push('/select_reg')
      },
      show_cart(){
        if ( this.$store.getters.get_use_point == 0 ){
          alert ("Your Cart is Empty.")
        }else{
          this.$refs.show_cart.open() 
        }
      },

      initializeLiff: function () {

        liff.init({
        liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
        })
        .then(() => {
          // start to use LIFF's api
          if (liff.isLoggedIn()) {

              liff.getProfile()
              .then(profile => {

                this.name =  profile.displayName 
                this.uid = profile.userId
                
              })
              .catch((err) => {
                console.log('error', err)
              })            
              

              //me.$router.push({ name: 'login' })
            }else{
              console.log('')
            }
        })
        .catch((err) => {
          console.log(err);
        });

        },

    make_event_id(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
    }
    return Date.now()+result;
    },

  },
  data: () => ({
    //
    cust_name : '',
      items: [
        { title: 'Customer Info' },
        { title: 'Change Password' },
        { title: 'Buy Credits' },
        { title: 'Order' },
        { title: 'Order Calendar' },
        { title: 'Order History' },
        { title: 'Logout' },
      ],
      phone : '',
      p_name : '',
      event_id : '',
      type_of_action : '',
      show_img : "" ,
      weeknum_arr : [],
      weeknum_index : 0 ,
      weeknum : 0 ,
      weeknum_name : '', 
  }),
  created() {
      
      this.$store.dispatch('set_user_ac', '' )

      console.log(this.$store.getters.get_user)


      let uri = window.location.search.substring(); 
      let params = new URLSearchParams(uri);
      this.phone = params.get("line_id");
      this.p_name = params.get("line_name");
      this.event_id = params.get("event_id");
      this.type_of_action = params.get("pkg");

      this.$store.dispatch('ac_type_of_action', this.type_of_action )

      //console.log("event_id is " + params.get("event_id"));

        if ( this.phone != null ){

        this.$store.dispatch('set_user_ac_code', this.phone )
        this.$store.dispatch('set_user_ac',this.p_name)                

        if ( this.phone == "reg" ){
            this.$router.push('/select_reg')
        }
        else if ( this.phone == "Guest" ){
            this.$router.push('/main')
        }else{

            axios.get("https://ws.plantifulbkk.shop/login_by_line.php",{
                      params: {
                          scode: this.phone ,
                          event_id :this.event_id ,
                      }
                  }).then(result=>{
                          //console.log('login_by_line -> '+result.data)
                          let res = result.data

                          if ( result.data.includes("incorrect") ){
                              
                            if ( sessionStorage.getItem('scode_storage') ){
                              this.onSignin_sus()
                            }                                                              
                              //alert("Username or Password Incorrect !")
                          }else{

                              let res3 = res.split(","); 

                              this.$store.dispatch('set_user_ac_code', res3[0] )
                              this.$store.dispatch('set_create_user_ac', ''  )
                              this.$store.dispatch('set_user_img', res3[1]  )
                              this.show_img = res3[1] 


                              this.$router.push('/main')
                              
                          }
                      })
                  .catch(function (error) {
                          alert("Error")
                          console.log(error)
              })

            }


        }  

      var user_active = this.$store.getters.get_user
      console.log(user_active)

      /*if (user_active == null || user_active == ''  ) {
        this.$router.push('/') 
      }*/

  },

  computed: {
    userIsAuthenticated () {
      return this.$store.getters.get_user_code !== '' && this.$store.getters.get_user_code !== 'Guest' && this.$store.getters.get_user_code !== 'reg'
    },
    userIsGuest () {

      console.log('get_user :'+this.$store.getters.get_user)
      console.log('get_user_code :'+this.$store.getters.get_user_code)

      return this.$store.getters.get_user == 'Guest' && this.$store.getters.get_user_code == 'Guest' 
    },   
  },

};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

html, body {
  font-family: 'Quicksand', sans-serif;
}

#app {
  font-family: 'Quicksand', sans-serif;
}

p {
  font-size: 1.2em;
}
@media (min-width: 48rem) {
  p {
    font-size: 2em;
  }
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
    font-size: 18px;
    background: #000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px; 
}


</style>