<template>
    <div>
        <v-img
            src="@/assets/images/planti_01.jpg"
            contain
            class="grey darken-4"
        ></v-img>
        <v-container >

            <v-layout row>
                <v-col>
                    <h3 class="text-right font-weight-normal bebas_25" >Your credit : {{this.$store.getters.your_credit}} meals</h3>
                    <h4 class="text-right font-weight-regular bebas_15" 
                    >Course  {{this.$store.getters.your_exp_title}}</h4>
                    <h5 class="text-right font-weight-regular " 
                    >Select a minimum of 2 meals /day .</h5>
                </v-col>
            </v-layout>
            <v-row justify="center">
                <v-icon @click="next_week()" >navigate_before</v-icon>
                <h4 class="Aktiv_A" > WEEK OF {{moment(weeknum_name).format('MMMM Do')}} </h4>
                <v-icon @click="next_week()" >navigate_next</v-icon>
            </v-row>
            <br>
            <v-divider></v-divider>
                <div v-for="(item  , index) in items" :key="index" >
                    <br v-if="weeknum == item.weeknum" >
                    <h2 v-if="weeknum == item.weeknum" class="text-center font-weight-normal bebas_25" >{{moment(item.date).format('dddd , MMMM Do')}}</h2>
                    <br v-if="weeknum == item.weeknum" >
                    <v-layout  v-if="weeknum == item.weeknum" row wrap align-center >                      
                            <v-flex  v-for=" (n ,index) in item.order" :key = "index" xs6 md3 >
                                <div class="text-center">
                                    <center>
                                        <v-card class="text-center ma-3" color="white"  
                                            @click="dispItem(n.name , n.descrip , n.image , n.ingredients)" >
                                            <span class="notify-badge">NEW</span>
                                            <v-img :src="url + n.image" contain  height="100%"  max-width="100%"  >
                                             </v-img>
                                            <v-dialog v-model="dialog" persistent width="400" >
                                                <v-card>
                                                    <v-container>
                                                        <h5 class="ma-3 text-end" @click="close_product_detail" >[ close ]</h5>
                                                        <v-card-title class="headline">{{sel_item_name}}</v-card-title>
                                                        <v-card-text>{{sel_item_descrip}}</v-card-text>
                                                        <v-img :src="url + sel_item_image" contain  height="100%"  max-width="100%"  >
                                                        </v-img>
                                                    </v-container>
                                                </v-card>    
                                            </v-dialog>
                                        </v-card> 
                                    </center>
                                    <v-row justify="center" class="text-center mx-7 my-4"
                                            style="height: 40px;">
                                        <h4 class="text-center font-weight-normal bebas_17">
                                            {{spit_name(n.name,1)}}</h4>  
                                        <h4 class="text-center font-weight-normal bebas_17">
                                            {{spit_name(n.name,2)}}</h4>      
                                    </v-row>

                                    <v-row  justify="center" class="text-center mx-7 my-3 "
                                            style="height: 40px;">
                                        <h5 class="font-weight-light"  v-line-clamp="2">
                                            {{n.descrip}}</h5>
                                    </v-row>
                                    <v-row justify="center" v-if="n.code !== '' && n.freeitem == '0'" >
                                        <v-btn text small color="black" dark 
                                        @click="removeItem(n.select_day ,n.code ,item.date )" >
                                            <v-icon>remove</v-icon>
                                        </v-btn>

                                            <h3>{{n.qty}}</h3>

                                        <v-btn text small color="black" dark 
                                        @click="addItem( n.select_day ,n.code ,item.date)" >
                                            <v-icon>add</v-icon>
                                        </v-btn>
                                    </v-row>
                                    <v-row justify="center" v-if="n.freeitem == '1'" style="color:#3AB881" 
                                    class="text-center font-weight-normal bebas_17">
                                        FREE SNACK
                                    </v-row>    
                                    <br>
                                </div>                
                            </v-flex>

                    </v-layout>
                    <v-divider  v-if="weeknum == item.weeknum"  ></v-divider>
                </div>
                <div>
                    <br>
                    <h2 class="text-center font-weight-normal bebas_25" >Add on</h2>
                    <br>

                        <v-layout row wrap align-center >                      
                            <v-flex  v-for="(item , index) in items_ad" :key="index"  xs6 md3 >
                                <div class="text-center">
                                    <center>
                                        <v-card class="text-center ma-3" color="white"  
                                        @click="dispItem(item.name , item.descrip , item.image , item.ingredients)" >
                                            <v-img :src="url + item.image" contain >
                                             </v-img>  
                                        </v-card> 
                                    </center>
                                    <v-row justify="center" class="text-center mx-7 my-3"
                                            style="height: 20px;">
                                        <h5 class="text-center font-weight-normal bebas_18" v-line-clamp="2">
                                            {{item.name}}</h5>  
                                    </v-row>
                                    <v-row  justify="center" class="text-center mx-7 my-3 "
                                            style="height: 40px;">
                                        <h5 class="font-weight-light"  v-line-clamp="2">
                                            {{item.descrip}}</h5>
                                    </v-row>
                                    <v-row  justify="center" class="text-center mx-7 my-3 "
                                            style="height: 20px;">
                                        <h5 class="font-weight-light bebas_18">
                                            {{item.price}} THB.</h5>
                                    </v-row>
                                    <div>

                                        <v-btn text small color="black" dark 
                                        @click="showItem_addon( item.seq ,item.code , item.name , item.price )" >
                                            <v-icon>add</v-icon>
                                        </v-btn>

                                    </div>
                                    <br>
                                        
                                    
                                </div>                
                            </v-flex>

                    </v-layout>
                    <v-divider></v-divider>

                        <v-dialog v-model="dialog_a" persistent width="300" >
                            <v-card>

                                    <v-container>    
                                        <h4 class="text-center font-weight-regular ma-5" >{{item_name_a}}</h4>
                                        <v-divider></v-divider>
                                    </v-container>  
                                    <v-container>
                                       <div v-for="(item, index) in items" :key="index"> 
                                    
                                                    <v-checkbox class="ml-10" v-model="ck_addon"   
                                                        color="Black" :value="item.date" v-if="item.tqty > 1"
                                                     >
                                                        <template v-slot:label>
                                                                 {{moment(item.date).format('dddd , MMMM Do')}}  
                                                        </template>
                                                    </v-checkbox>

                                             </div>
                                        <center>
                                            <v-btn class="font-weight-bold" tile color="black" dark 
                                            @click="addItem_addon()">Add order</v-btn>
                                        </center>
                                    </v-container>
                            </v-card>
                        </v-dialog>

                </div>

        <div class="text-center">
            <br> 

                    <br>
                    
                <h4 class="text-center font-weight-regular mt-5"> Order before 12PM day prior to receiving your first delivery! We deliver fresh daily from our kitchen on Mondays-Friday, from 7.00-11.30AM.</h4>
            <br>    
                <v-dialog v-model="dialog_s" width="600" >
                    <v-card>
                        <v-card-title class="headline">Cart</v-card-title>
                            <v-container>    
                                <h4 class="text-center font-weight-regular ma-5" >Order before 3PM day prior to receiving your first delivery! We deliver fresh daily from our kitchen on Mondays-Friday, from 7.00-11.30AM.</h4>
                                <v-divider  class="text-center mx-5" ></v-divider>
                            </v-container>    
                            <v-container class="ma-3">

                                <div v-for="(item , index) in items" :key="index" >
                                    <span class="font-weight-bold ma=7 bebas_18"  >{{moment(item.date).format('dddd , MMMM Do')}}</span>   
                                    <span class="font-weight-light ml-5" style="color:red" v-if="item.tqty==1" >**</span> 
                                    <div class="text-center"  v-if="item.tqty==0">
                                         <span>-- no item --</span>         
                                    </div>                     
                                    <table class="mx-0" width="100%">
                                        <tr  v-for=" (n , index) in item.order" :key = "index" >            
                                            <td width="75%" v-if="n.qty > 0"><h5 class="font-weight-light">{{n.name}}</h5>
                                            <h5 v-if="n.freeitem == '1'" style="color:#3AB881"  >( Free Item)</h5></td>
                                            <td width="10%" v-if="n.qty > 0"><h5 class="font-weight-regular">{{n.qty}}</h5></td>
                                            <td width="15%" v-if="n.qty > 0"><h5 class="font-weight-light">Qty.</h5></td>     
                                        </tr>
                                    </table>
                                </div>
                                <span class="font-weight-bold ma=7 bebas_18" >Add on</span>  
                                <br>
                                    
                                    <table class="mx-0" width="100%">
                                        <tr  v-for="(item , index) in addonSort" :key="index" >            
                                            <td width="75%" ><h5 class="font-weight-light">{{item.itemname}} on  {{moment(item.date_adn).format('dddd , MMMM Do')}}</h5></td>
                                            <td width="10%" ><h5 class="font-weight-regular">{{item.price}}</h5></td>
                                            <td width="15%" ><h5 class="font-weight-light">THB.</h5></td>     
                                        </tr>
                                    </table>
                                <br>    
                                <center><span class="font-weight-bold ma=7 bebas_18" v-if="this.item_price_total> 0" >
                                    Total : {{this.item_price_total}} THB.</span></center>
                            </v-container>     
                            <v-container>    
                                <v-divider  class="text-center mx-5" ></v-divider>
                                <br>
                                <div class="font-weight-light ml-5" >
                                    Delivery :
                                    <v-radio-group
                                        v-model="deli_at" row 
                                        >
                                        <v-radio
                                            @click="show_add('work')"
                                            label="Work"
                                            value="work"
                                            color="black"
                                        ></v-radio>
                                        <v-radio
                                        @click="show_add('home')"
                                            label="Home"
                                            value="home"
                                            color="black"
                                        ></v-radio>
                                    </v-radio-group>
                                    <span class="font-weight-light ml-5" >{{deli_add}} </span>
                                </div>
                            </v-container>
                            <v-container>
                                <v-text-field  class="text-center mx-7"
                                    v-model="customer.Allergies"
                                    label="Allergies"/>  
                                <v-text-field  class="text-center mx-7"
                                    v-model="customer.remark"
                                    label="Remark"/>  
                            </v-container>
                            <div class="text-center my-3">
                                <v-btn class="font-weight-bold" tile color="black" dark 
                                    @click="confirm_order" >Confirm Order</v-btn>
                            </div>
                            <br>
                            <h5 style="color:red" class="ma-8" >**Please choose more than 2 items per day. </h5>
                            <br><br>
                    </v-card>
                </v-dialog>
        </div>

        </v-container>

        <v-dialog v-model="dialog_c" width="500" >
            <v-card>
                <v-container>    
                    <h3 class="text-center ma-5" >Your line name is {{$store.getters.get_user}}</h3>
                    <v-divider></v-divider>
                </v-container>  
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>       
                       <h3>Please fill your information</h3>
                        <v-text-field
                            v-model="customer_add.tel"
                            label="tel"
                            :rules="otherRules" 
                            required />   
                        <v-text-field
                            v-model="customer_add.email"
                            label="email"
                            :rules="otherRules" 
                            required />         
                        <h3>Address</h3>    

                        <v-radio-group
                            v-model="deli_at" row 
                            >
                            <v-radio
                                @click="show_add('work')"
                                label="Work"
                                value="work"
                                color="black"
                            ></v-radio>
                            <v-radio
                            @click="show_add('home')"
                                label="Home"
                                value="home"
                                color="black"
                            ></v-radio>
                        </v-radio-group>

                        <v-text-field
                            v-model="customer_add.address1"
                            label="Address1"
                            :rules="otherRules"  
                            required />
                        <v-text-field
                            v-model="customer_add.address2"
                            label="Address2"
                            :rules="otherRules"  
                            required />
                        <v-text-field
                            v-model="customer_add.zipcode"
                            label="zipcode"
                            :rules="otherRules" 
                            required />                          
                        <h3>Ohter Information</h3>       
                        <v-text-field
                            v-model="customer_add.Allergies"
                            label="Allergies"/>     
                        <v-text-field
                            v-model="customer_add.remark"
                            label="Remark"/>  
                        <v-btn class="font-weight-light" tile :disabled="!valid"
                            @click="add_cust_detail">
                            submit
                        </v-btn>
                        <v-btn class="font-weight-light" tile color="black" dark   
                        :disabled="true"
                        @click.native="clear">clear</v-btn>
                    </v-form>
                </v-container>
             </v-card>
        </v-dialog>

    </div>
    
</template>

<script>
import axios from "axios"
import Qs from 'qs'
import moment from 'moment'
import _ from 'lodash';

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.transformRequest = [obj => Qs.stringify(obj)]

export default {
    name: "main_page_comp",
    components:{
    },
    data() {
        return {
            your_credit : 0 ,
            lockdate : true ,
            url : "https://ws.plantifulbkk.shop/images/" ,
            items :[],
            items_ad :[],
            weeknum_arr : [],
            item_addon_seq : 0,
            deli_add:'',
            deli_add_1:'',
            deli_add_2:'',
            dialog: false ,
            dialog_s: false ,
            windows_w : 0 ,
            dialog_a: false ,
            dialog_c: false ,
            week_title : ' This Week ',
            weeknum_index : 0 ,
            weeknum : 0 ,
            weeknum_name : '', 
            chk_row_comp : 0 ,
            point_rank : 0 ,
            blank_f : "       ",
            deli_at : 'work',
            orderf_no : "" , 
            item_code_a : "",
            item_name_a : "",
            item_price_a : 0,
            item_price_total : 0,
            json_order :"",
            order_gen_g : "" ,
            total_point_g : 0 ,
            ck_addon : [] ,
            old_qty_addon : 0 ,
            svr_qty : 0 ,
            ary_addon : [[],[],[],[],[],[],[],[],[],[],[],[]],
            old_array_addon : [], 
            ary_add_addon : [] ,
            time_cus : 11 ,
            sel_item_name:'',
            sel_item_descrip:'',
            sel_item_image:'',
            customer:{
                code: '',
                Allergies:'',
                remark:'',
            }, 
            valid: false,
            customer_add :{
                code: '',
                name: '',
                new_pass1:'',
                new_pass2:'',
                address1:'',
                address2:'',
                zipcode:'',
                tel:'',
                email:'',
                Allergies:'',
                remark:'',
                latitude:'',
                longitude:'',
            },    
            codeRules: [
            v => !!v || 'This field is required',
            ],
            nameRules: [
            v => !!v || 'This field is required',
            ],        
            otherRules: [
            v => !!v || 'This field is required',
            ],
            value: String,
        }
    },

    mounted(){

        this.load_weeknum()
        this.load_day()
        this.customer.Allergies = this.$store.getters.your_allergies
        this.customer.remark = this.$store.getters.your_remark
        this.customer.code = this.$store.getters.get_user_code
        this.customer_add.code = this.$store.getters.get_user_code
        //this.ary_addon = this.$store.getters.get_ary_addon
        this.$store.dispatch('ac_show_cart', 1 )
        this.$store.dispatch('ac_ary_add_addon', [] )

    },
    created : function () {
        this.moment = moment;
        this.windows_w = window.innerWidth

    },
    computed: {
        addonSort() {
        return _.orderBy(this.ary_add_addon, 'date_adn' ); 
        }
    },
    methods :{

        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },
        make_event_id(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
        }
        return Date.now()+result;
        },
        dispItem (itemname , itemdescrip , itemimage , ingredients) {
            this.sel_item_name = itemname
            this.sel_item_descrip = itemdescrip
            this.sel_item_image = itemimage
            this.sel_ingredients = ingredients
            this.dialog = true
        },
        spit_name( name , no ){
            let line_s = 35
            let seq = no
            let name1 = name
            if ( this.windows_w < 500 ){
                line_s = 17
            }else{
                line_s = 35
            }
            if ( name1.length > line_s ){
                while ( name1.substring(line_s-1, line_s) != " " ) {
                    
                    line_s = line_s - 1
                }
                if ( seq == 1){
                    name1 = name1.substring(0, line_s) 
                }else{
                    name1 = name1.substring(line_s, 100)
                }
            }else{
                if ( seq == 1 ){
                    name1 = ""
                }
            }

            return name1
        },
        dispconfirm ( ) {
            this.customer_add_comp () 
            
            console.log( " -->  " + JSON.stringify(this.ary_addon))

            this.check_over_time()

            let ii = 0;
            while (ii < this.items.length) {

                this.get_free_by_promo( this.items[ii].date )
                ++ii;
            }

            let date_del = ""    
            let i = 0;
            while (i < this.items.length) {
                if (this.items[i].tqty < 2 ) {      
                    
                    date_del = this.items[i].date  
                    var j= 0;
                    while (j < this.ary_add_addon.length) {
                        if (this.ary_add_addon[j].date_adn === date_del) {      
                            this.ary_add_addon.splice(j, 1);
                        } 
                        ++j;
                    }  
                } 
                ++i;
                
            }
            this.item_price_total = 0
            var k= 0;
            while (k < this.ary_add_addon.length) {
                this.item_price_total = this.item_price_total + parseInt(this.ary_add_addon[k].price)
                ++k;
            }

            if ( this.$store.getters.get_create_user =='Y' ){
                this.dialog_c = true
            }else{
                this.get_address()
                this.dialog_s = true    
            }
        },
        add_cust_detail(){

            if ( this.$refs.form.validate() )
                {

                    axios.get("https://ws.plantifulbkk.shop/post_new_customer.php",{
                        params: {
                            scode: this.customer_add ,
                            sdeli: this.deli_at ,
                        }
                    }).then(result=>{
                            console.log(result.data)
                            this.$store.dispatch('set_create_user_ac', 'N' )
                            this.dialog_c = false
                            this.get_address()
                            this.dialog_s = true  
                        })
                    .catch(function (error) {
                            alert("Error : Cannot Edit customer !!")
                            console.log(error)
                    })
                }else{
                    alert('Please fill out this form.  ')
                }

        },
        show_add( item){
            if ( item == 'work' ){
                this.deli_add = this.deli_add_1 
            }
            if ( item == 'home' ){
                this.deli_add = this.deli_add_2 
            }                
        },
        add_cust_info(){
            this.dialog_c = false
        },
        close_product_detail(){
            this.dialog = false
        },
        confirm_order ( ) {
            if ( this.check_order() ){
                    
                    var d = new Date()
                    var n = d.getDate()+d.getTime()
                    this.order_no = this.$store.getters.get_user.substring(0,8) + n  
                    this.dialog_s = false

                    if ( this.item_price_total > 0 ){
                        this.order_encode()
                        this.send_order_temp()
                        this.send_allergies()
                        window.location.href = "https://api.plantifulbkk.shop/addon_pay.php?addonamt=" + this.item_price_total
                        +"&point="+this.total_point_g+"&id="+this.order_no+"&scode="+this.$store.getters.get_user_code
                    }else{
                        this.order_encode()
                        this.send_order()
                        this.send_allergies()
                        this.show_suscess()
                    }
                }
        },
        order_encode(){

            let add_items = {}
            let json_output = {}
            let order_gen = "["
            let order_length = 0
            let total_point = 0           
            let i1 = 0
            let j1 = 0
            for (i1 = 0; i1 < this.items.length ; i1++) {
                if ( this.items[i1].tqty > 0  ){
                    for (j1 = 0; j1 < this.items[i1].order.length; j1++) {
                        if ( this.items[i1].order[j1].qty > 0 ){
                            order_length = order_length + 1
                            if ( this.items[i1].order[j1].freeitem == '0' ){
                                total_point = total_point + parseInt(this.items[i1].order[j1].qty)
                            }

                        }
                    }
                }
            }
            this.total_point_g  = total_point
            let i = 0
            let j = 0
            let k = 0
            for (i = 0; i < this.items.length ; i++) {
                if ( this.items[i].tqty == 0  ){
                    console.log("" + this.items[i].date + "is zero")
                }else{

                    for (j = 0; j < this.items[i].order.length; j++) {

                        if ( this.items[i].order[j].qty > 0 ){

                            add_items.tran_idi = this.makeid(20) 
                            add_items.cus_code = this.$store.getters.get_user_code
                            add_items.date1 = this.items[i].date
                            add_items.p_code = this.items[i].order[j].code
                            add_items.qty = this.items[i].order[j].qty
                            add_items.freeitem = this.items[i].order[j].freeitem                            
                            add_items.price = this.items[i].order[j].price
                            add_items.id = this.order_no
                            add_items.allergies = this.customer.Allergies
                            add_items.remark = this.customer.remark
                            add_items.deli_add = this.deli_at
                            add_items.rang_week = ""
                            
                            json_output = add_items
                            k = k + 1
                            order_gen = order_gen + JSON.stringify(json_output)
                            if ( order_length == k ){
                                console.log(k)
                            } else {
                                order_gen = order_gen + ','
                            }
                        }
                    }

                }   

            }    
            // ad-on saction
            if ( this.ary_add_addon.length > 0 ){
                order_gen = order_gen + ','
            }
            k = 0

            for (j = 0; j < this.ary_add_addon.length; j++) {

                    add_items.tran_idi = this.makeid(20) 
                    add_items.cus_code = this.$store.getters.get_user_code
                    add_items.date1 = this.ary_add_addon[j].date_adn
                    add_items.p_code = this.ary_add_addon[j].itemcode
                    add_items.qty = 1
                    add_items.price = this.ary_add_addon[j].price
                    add_items.id = this.order_no
                    add_items.allergies = this.customer.Allergies
                    add_items.remark = this.customer.remark
                    add_items.deli_add = this.deli_at
                    add_items.rang_week = ""

                    json_output = add_items
                    k = k + 1
                    order_gen = order_gen + JSON.stringify(json_output)

                    if ( this.ary_add_addon.length == k ){
                        console.log(k)
                    } else {
                        order_gen = order_gen + ','
                    }

            }
            order_gen = order_gen + ']'
            this.order_gen_g =  order_gen   
            //------
            console.log('order_gen - > ' +  order_gen)
        },
        send_order(){
            axios.get("https://ws.plantifulbkk.shop/post_add_order_c.php",{
                    params: {
                        scode: this.$store.getters.get_user_code ,
                        sorder: this.order_gen_g ,
                        point: this.total_point_g ,
                        event_id : this.make_event_id(5) ,
                    }
                }).then(result=>{
                        console.log(result)
                        this.load_weeknum()
                        this.ary_add_addon = [] 
                        this.load_day()
                        this.dialog_s = false
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })
        },     
        send_order_temp(){
            axios.get("https://ws.plantifulbkk.shop/post_add_order_c_temp.php",{
                    params: {
                        scode: this.$store.getters.get_user_code ,
                        sorder: this.order_gen_g ,
                        point: this.total_point_g ,
                        event_id : this.make_event_id(5)  ,
                    }
                }).then(result=>{
                        console.log('<-->'+result)
                        this.dialog_s = false
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })
        },          
        send_allergies(){

            axios.get("https://ws.plantifulbkk.shop/post_edit_allergies.php",{
                    params: {
                        scode: this.customer  ,
                    }
                }).then(result=>{
                        console.log('al is = '+result.data)
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })

        },
        count_day_order(){

            let i = 0
            let j = 0
            let k = 0
            for (i = 0; i < 5; i++) {
                if ( this.items[i].tqty > 0  ){
                    for (j = 0; j < this.items[i].order.length; j++) {
                        if ( this.items[i].order[j].qty > 0 ){
                            k = k + 1
                        }
                    }
                }
            }
            
            return k

        },

        get_free_by_promo( item_date){

            var ind = '' 
            var free_code = ''
            var cnt_free_sku = 0
            var num_free = 0
            var sum_sell_qty = 0 
            var addr_free_item = 0

            for (var i = 0; i < this.items.length; i++) {
                    if ( this.items[i].date == item_date ){
                        ind = i 
                    }
                }

            for (var j = 0; j < this.items[ind].order.length; j++) {
                if ( this.items[ind].order[j].freeitem  == '1' ){
                    free_code = this.items[ind].order[j].code
                    num_free = this.items[ind].order[j].sell_to_free_qty
                    addr_free_item = j
                    this.items[ind].order[j].qty = 0
                    cnt_free_sku = cnt_free_sku + 1
                }
            }

            if ( cnt_free_sku == 0){
                return
            }

            for (var k = 0; k < this.items[ind].order.length; k++) {
                if ( this.items[ind].order[k].freeitem  == '0' ){
                      sum_sell_qty = sum_sell_qty + parseInt(this.items[ind].order[k].qty)
                }
            }


            if ( sum_sell_qty >= num_free ){
                this.items[ind].order[addr_free_item].qty = 1
                console.log(free_code)
            }

        },
        convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        },

        addItem( day , code , item_date){

            var date = new Date();
            date.setDate(date.getDate() + 1);
            var time_now = parseInt(date.getHours())
            var ind = '' 
            var ind_b = ''

            for (var i = 0; i < this.items.length; i++) {
                    if ( this.items[i].date == item_date ){
                        ind = i 
                        if ( this.$moment(this.items[i].date).format("DD") == date.getDate() & time_now > this.time_cus ){
                            alert(" Couldn't choose this date after 12:00 PM. !! ")
                            return
                        }
                    }
                }

            ind_b = this.index_b(ind , code)

            var old_qty = parseInt(this.items[ind].order[ind_b].qty)

            if ( this.$store.getters.your_credit > 0 ){
                this.$store.dispatch('your_credit', this.$store.getters.your_credit - 1)
                this.$store.dispatch('set_use_point', this.$store.getters.get_use_point + 1 ) 

                this.items[ind].order[ind_b].qty = old_qty + 1
                this.items[ind].tqty = parseInt(this.items[ind].tqty)  + 1

                this.$store.dispatch('ac_use_items', this.items ) 


                axios.get("https://ws.plantifulbkk.shop/post_product_temp.php",{
                        params: {
                            scode: this.$store.getters.get_user_code ,
                            date: item_date ,
                            p_code: code ,
                            qty: 1 ,
                            tran_idi : this.makeid(20) ,
                        }
                    })

            } 
            if ( this.$store.getters.your_exp_credit > 0 ){
                this.$store.dispatch('your_exp_credit', this.$store.getters.your_exp_credit - 1 ) 
            }
            this.show_credit()

        },
        addItem_addon(){


            let code = this.item_code_a
            let name = this.item_name_a
            let price = this.item_price_a
            let seq = this.item_addon_seq

            this.ary_addon[this.item_addon_seq] = this.ck_addon

console.log( JSON.stringify(this.ary_addon) )
                
                this.removeItemAll( this.ary_add_addon , code )
                let ar_to_str = this.ck_addon.join(',')
                let strArray = ar_to_str.split(",");

                for(var i = 0; i < strArray.length; i++){
                    if ( strArray[i] !== '' ){
                        this.ary_add_addon.push( { seq : seq ,  date_adn : strArray[i]  , itemcode : code , itemname : name , qty : 1 , price : price } )
                    }
                }


            this.$store.dispatch('ac_ary_add_addon',  this.ary_add_addon )
            this.$store.dispatch('ac_ary_addon',  this.ary_addon )
            this.dialog_a = false

        },
         removeItemAll(arr, value) {

            var i = 0;
            while (i < arr.length) {
                if (arr[i].itemcode === value) {      
                arr.splice(i, 1);
                } else {
                ++i;
                }
            }
            return arr;
        } ,

        showItem_addon( seq ,code ,name ,price ){

            this.old_array_addon = this.ary_addon[seq]
            this.item_addon_seq = seq
            this.ck_addon = this.ary_addon[seq]
            this.old_qty_addon = this.ary_addon[seq].length 
            this.item_code_a = code
            this.item_name_a = name
            this.item_price_a = price

            this.dialog_a = true 
        },
         removeItem( day , code , item_date){

           var date = new Date();
            date.setDate(date.getDate() + 1);
            var time_now = parseInt(date.getHours())
            var ind = '' 
            var ind_b = ''

            for (var i = 0; i < this.items.length; i++) {
                    if ( this.items[i].date == item_date ){
                        ind = i 
                        if ( this.$moment(this.items[i].date).format("DD") == date.getDate() & time_now > this.time_cus ){
                            alert(" ouldn't choose this date after 12:00 PM. !! ")
                            return
                        }
                    }
                }

            ind_b = this.index_b(ind , code)

            var old_qty = parseInt(this.items[ind].order[ind_b].qty)
            if ( old_qty == 0 ){
                this.items[ind].order[ind_b].qty = 0
            }else{
                this.items[ind].order[ind_b].qty = old_qty - 1
                this.$store.dispatch('your_credit', this.$store.getters.your_credit + 1)
                this.$store.dispatch('set_use_point', this.$store.getters.get_use_point - 1 ) 
                this.items[ind].tqty = parseInt(this.items[ind].tqty)  - 1
                if (this.$store.getters.your_credit > this.point_rank ){
                    this.$store.dispatch('your_exp_credit', this.$store.getters.your_exp_credit + 1)
                }

                axios.get("https://ws.plantifulbkk.shop/post_product_temp.php",{
                    params: {

                        scode: this.$store.getters.get_user_code ,
                        date: item_date ,
                        p_code: code ,
                        qty: -1 ,
                        tran_idi : this.makeid(20) ,
                    }
                }).then(result=>{
                        console.log('<-->'+result)
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })

                this.show_credit()
            }

            //console.log(this.items +day +' '+code )

        },
        customer_add_comp () {

        axios.get('https://ws.plantifulbkk.shop/get_customer_address_chkt.php',{
            params: {
                scode: this.$store.getters.get_user_code ,
            }
        }).then(response => {
            if ( response.data == 0 ){
                this.$store.dispatch('set_create_user_ac','Y')
            }
        });
      },
        
        get_address () {
            axios.get("https://ws.plantifulbkk.shop/get_customer_address.php",{
                params: {
                    scode: this.$store.getters.get_user_code ,
                }
            }).then(result=>{
                    //console.log(result.data)
                    let res = result.data
                        let res3 = res.split(";");
                        this.deli_add_1 = res3[0]
                        this.deli_add_2 = res3[1]
                        if ( this.deli_at == 'work'){
                            this.deli_add = res3[0]
                        }   
                        if ( this.deli_at == 'home'){
                            this.deli_add = res3[1]
                        }

                })
            .catch(function (error) {
                    alert("Error")
                    console.log(error)
                })
        },
        show_credit(){

            let toast = this.$toasted.show("Your credit is "+this.$store.getters.your_credit , { 
                theme: "outline", 
                position: "top-right", 
                duration : 1000
            });
            toast.show()

        },
        show_suscess(){

            let toast = this.$toasted.show("Your Order number is "+this.order_no, { 
                theme: "outline", 
                position: "top-right", 
                duration : 2000
            });
            toast.show()

        },
        ret_chk( pnt ){
            for (var i = 0; i < this.items.length; i++) {
                if ( this.items[i].tqty ==  pnt ){
                    return true
                }
            }
            return false
        },
        ret_chk_tot(){
            var tot = 0
            for (var i = 0; i < this.items.length; i++) {
                tot = tot + this.items[i].tqty
            }
            if ( tot == 0){
                return true
            }
            return false
        }
        ,
        check_order(){
            
            //console.log(this.items)

            if ( this.ret_chk_tot() ){
                
                let toast = this.$toasted.show("Your cart is empty!!", { 
                theme: "outline", 
                position: "top-right", 
                duration : 1000
                });
                toast.show()
                
                return false

            }

            if ( this.ret_chk(1) ){
                
                let toast = this.$toasted.show("Please choice more then 2 itme per day !!", { 
                theme: "outline", 
                position: "top-right", 
                duration : 1000
                });
                toast.show()

                return false

            }

            return true
        },
        index_a(day){

            if ( day == 'Mon' ){
                return 0
            }
            if ( day == 'Tue' ){
                return 1
            }
            if ( day == 'Wed' ){
                return 2
            }
            if ( day == 'Thu' ){
                return 3
            }
            if ( day == 'Fri' ){
                return 4
            }                        
        },
        index_b(ind , code){

            var i = 0
            for (i = 0; i < this.items[ind].order.length; i++) {

                if ( this.items[ind].order[i].code  == code ){
                    return i   
                }
            }
        },
        next_week(){
            this.$router.push('/main')

        },
        async load_weeknum(){
        
             await axios.get("https://ws.plantifulbkk.shop/get_weeknum.php",{
                params: {
                        }
                    }).then(result=>{
                this.weeknum_arr = result.data

                //console.log( this.weeknum_arr  )
                this.weeknum_index = 1
                this.weeknum = this.weeknum_arr[this.weeknum_index].weeknum
                this.weeknum_name = this.weeknum_arr[this.weeknum_index].date

            })
        },
        async load_day(){
            //this.your_credit = this.$store.getters.your_credit 
            var cr_1 = []
            let currentDate = new Date();
            if (currentDate.getDay() < 5 ) {
            this.lockdate = false     
            }
        
             await axios.get("https://ws.plantifulbkk.shop/get_weekday_v4.php",{
                params: {
                            scode: this.$store.getters.get_user_code
                        }
                    }).then(result=>{
                this.items = result.data
                this.$store.dispatch('ac_use_items', this.items ) 
                var i = 0
                this.svr_qty = 0
                while (i < this.items.length) {
                    this.svr_qty = this.svr_qty + parseInt(this.items[i].tqty )
                    ++i;
                }
                console.log(' item = '+JSON.stringify(this.items) )
            }) 

              await axios.get("https://ws.plantifulbkk.shop/get_weekday_adonn.php",{
                        params: {
                            
                        }
                    }).then(result=>{
            
              this.items_ad = result.data
                console.log(' items_ad data = '+JSON.stringify(this.items_ad))
            }) 

        await axios.get("https://ws.plantifulbkk.shop/get_credit.php",{
                        params: {
                            scode: this.$store.getters.get_user_code
                        }
                    }).then(result=>{


            if ( result.data != '' ){
                cr_1 = result.data.split(",")
                this.$store.dispatch('your_credit', cr_1[0] - this.svr_qty ) 
                this.$store.dispatch('your_exp_credit', cr_1[1] - this.svr_qty) 
                this.$store.dispatch('your_exp_title', cr_1[2] ) 
                this.point_rank = cr_1[0] - cr_1[1]
                this.$store.dispatch('set_use_point', this.svr_qty ) 
            }else{
                this.$store.dispatch('your_credit', 0 ) 
                this.$store.dispatch('your_exp_credit', 0 ) 
                this.$store.dispatch('your_exp_title', '' ) 
                this.$store.dispatch('set_use_point', 0) 
            } 

            console.log(" get credit commplete")

            })  

        },  
    },
}
</script>

<style>

    @font-face {
        font-family: 'Aktiv Grotesk';
        src: local('Aktiv Grotesk Light'), local('Aktiv-Grotesk-Light'),
            url('../assets/font/AktivGroteskCorp-Light.woff2') format('woff2'),
            url('../assets/font/AktivGroteskCorp-Light.woff') format('woff'),
            url('../assets/font/AktivGroteskCorp-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
    font-family: bebas ;
    src: url("../assets/font/BebasNeue Bold.ttf") ;
    }
    .bebas_25{
    font-family: bebas;
    font-size:25px;
    }
    .bebas_17{
    font-family: bebas;
    font-size:17px;
    }
    .bebas_18{
    font-family: bebas;
    font-size:18px;
    }
    .bebas_15{
    font-family: bebas;
    font-size:15px;
    }

    .Aktiv_A{
    font-family: 'Aktiv Grotesk';
    font-size:18px;
    }

.notify-badge{
    position: absolute;
    right:-20px;
    top:10px;
    background:red;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding:5px 10px;
    font-size:20px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

#lblCartCount {
    font-size: 18px;
    background: #000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px; 
}


</style>