<template>
    <div>
    <br><br>
    <center><h2>Change Password</h2></center>
    <v-container grid-list-xs>
        <v-layout justify-center> 
            <v-flex xs6>
                <v-form ref="form" v-model="valid" lazy-validation>  
                <v-text-field
                    v-model="c_data.new_pass1"
                    label="Enter New Password"
                    :append-icon="value ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    :rules="passRules"  
                    required 
                ></v-text-field>
                <v-text-field
                    v-model="c_data.new_pass2"
                    label="Confirm New Password"
                    :append-icon="value ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    :rules="passRules"  
                    required 
                ></v-text-field>
                <center>
                    <v-btn class="font-weight-light" tile  :disabled="!valid"
                        @click="cng_pass">
                        submit
                    </v-btn>
                </center>    
                <v-btn class="font-weight-light" tile color="black" dark   
                :disabled="true"
                @click.native="clear">clear</v-btn>
                </v-form>
            </v-flex>
        </v-layout>   
    </v-container>
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "change_password__comp",
    components:{

    },
  data() {
    return {
            valid: false,
            c_data:{
                code: '',
                new_pass1 : '',
                new_pass2 : '',   
            }, 
            pass_chk : 2 ,
            passRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 6) || 'This field must be more than 6 characters'
            ],
            value: String,
        }
    },
    created () {
        this.valid =  false
        this.pass_chk = 2
        this.c_data.code = this.$store.getters.get_user_code 
    },
    methods :{
        clear_pass(){
            this.pass_chk = this.pass_chk - 1
            this.c_data.new_pass1 = ''
            this.c_data.new_pass2 = '' 
        },
        cng_pass(){
            
            if ( this.c_data.new_pass1 == '' || this.c_data.new_pass2 == '' ){
                alert("Password Can't Blank !!")
                this.clear_pass()
                return;
            }
            if ( this.c_data.new_pass1 != this.c_data.new_pass2 ){
                alert("Password Don't Match!!")
                this.clear_pass()
                return;
            }
            
            if ( this.$refs.form.validate() )
                {

                axios.get("https://ws.plantifulbkk.shop/post_edit_password.php",{
                    params: {
                        scode: this.c_data ,
                    }
                }).then(result=>{
                        console.log(result.data)
                        this.$refs.form.reset()
                        alert("Change Password Success !!")
                    })
                .catch(function (error) {
                        alert("Error : Cannot Change Password !!")
                        console.log(error)
            })
                
            }else{
                alert('Please fill out this form.  ')
            }
        }
    }
}
</script>

<style>

</style>