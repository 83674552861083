<template>
    <div>
        <br>
        <center><h2 id="cus_head">Customer Info</h2></center>
    <v-container grid-list-xs>
        <v-layout justify-center>
            <v-flex xs6>
                <h5 style="color:red" >* This field is required </h5>
                <v-form ref="form" v-model="valid" lazy-validation>
                <h5><v-text-field
                    v-model="customer.email"
                    label="* email for login"
                    :rules="otherRules" 
                    required />

                <br>    
                <v-text-field
                    v-model="customer.name"
                    :rules="nameRules"                    
                    label="* Name"
                    required />
                <v-text-field
                    v-model="customer.tel"
                    label="* tel"
                    :rules="otherRules" 
                    required /></h5>    
                <h3>Work Address</h3>    
                <h5><v-text-field
                    v-model="customer.address1"
                    label="* Address1"
                    :rules="otherRules"  
                    required />
                <v-text-field
                    v-model="customer.address2"
                    label="* Address2"
                    :rules="otherRules"  
                    required />
                 <v-text-field
                    v-model="customer.zipcode"
                    label="* zipcode"
                    :rules="otherRules" 
                    required /> </h5>              
                <h3>Home Address</h3>    
                <h5><v-text-field
                    v-model="customer.h_address1"
                    label="* Address1"
                    :rules="otherRules"  
                    required />
                <v-text-field
                    v-model="customer.h_address2"
                    label="* Address2"
                    :rules="otherRules"  
                    required />
                 <v-text-field
                    v-model="customer.h_zipcode"
                    label="* zipcode"
                    :rules="otherRules" 
                    required /></h5>       
                <h3>Ohter Information</h3>       
                <h5><v-text-field
                    v-model="customer.Allergies"
                    label="Allergies"/>     
                <v-text-field
                    v-model="customer.remark"
                    label="Remark"/></h5>  
                <center>
                    <v-btn class="font-weight-light" tile :disabled="!valid"
                        @click="edit_customer">
                        submit
                    </v-btn>
                </center>    
                <v-btn class="font-weight-light" tile color="black" dark   
                :disabled="true"
                @click.native="clear">clear</v-btn>
                </v-form>
            </v-flex>
        </v-layout>   
    </v-container>
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "customer_info_comp",
    components:{

    },
  data() {
    return {
            valid: true,
            customer:{
                code: '',
                name: '',
                email:'',
                password:'',
                address1:'',
                address2:'',
                zipcode:'',
                tel:'',
                Allergies:'',
                remark:'',
                latitude:'',
                longitude:'',
                h_address1:'',
                h_address2:'',
                h_zipcode:'',
                h_latitude:'',
                h_longitude:'',
            },    
            codeRules: [
            v => !!v || 'This field is required',
            ],
            nameRules: [
            v => !!v || 'This field is required',
            ],        
            passRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 6) || 'This field must be more than 6 characters'
            ],
            otherRules: [
            v => !!v || 'This field is required',
            ],
        }
    },
    created () {
        this.edit_data ()   
    },
    mounted() {
        if ( this.$store.getters.get_user == 'Guest' ){
            document.getElementById("cus_head").innerHTML = "Create your Account" 
        }
    },
    methods :{
    edit_data () {


        axios.get('https://ws.plantifulbkk.shop/get_customer_edit.php',{
            params: {
                scode: this.$store.getters.get_user_code ,
            }
        }).then(response => {
        this.customer = JSON.parse(JSON.stringify(response.data))
        console.log(response.data)
        });
      },
            edit_customer(){

                if ( this.$refs.form.validate() )
                {

                    axios.get("https://ws.plantifulbkk.shop/post_edit_customer.php",{
                        params: {
                            scode: this.customer ,
                        }
                    }).then(result=>{
                            console.log(result.data)
                            this.$refs.form.reset()
                            alert("Edit Information Complete")
                            this.edit_data()
                            this.$router.push('/main')

                        })
                    .catch(function (error) {
                            alert("Error : Cannot Edit customer !!")
                            console.log(error)
                    })
                }else{
                    alert('Please fill out this form.  ')
                }

      },
    }
}
</script>

<style>

</style>