<template>
    <div>
        <mainpage1/>
    </div>
</template>

<script>
import mainpage1 from "@/components/main_order_1.vue";

export default {


  name: 'main_view',
  components: {
    mainpage1,
  }
}
</script>
