<template>
    <div>
        <v-dialog v-model="dialog_s" width="600" >
            <v-card>
                    <v-container>    
                        <h5 class="ma-3 text-end" @click="close_order_detail" >[ close ]</h5>
                        <h2 class="headline ml-5" >Cart</h2>
                        <h6 class="text-center font-weight-regular ma-5" >Order before 12PM for next day delivery. We deliver fresh daily on Mon-Fri from 7.00-11.30AM.</h6>
                        <v-divider  class="text-center mx-5" ></v-divider>
                    </v-container>    
                    <v-container class="ma-3">

                        <div v-for="(item , index) in items" :key="index" >
                            <span class="font-weight-bold ma=7 bebas_18"  >{{moment(item.date).format('dddd , MMMM Do')}}</span>   
                            <span class="font-weight-light ml-5" style="color:red" v-if="item.tqty==1" >**</span> 
                            <div class="text-center"  v-if="item.tqty==0">
                                    <span>-- no item --</span>         
                            </div>                     
                            <table class="mx-0" width="100%">
                                <tr  v-for=" (n , index) in item.order" :key = "index" >            
                                    <td width="75%" v-if="n.qty > 0"><h5 class="font-weight-light">{{n.name}}</h5>
                                    <h5 v-if="n.freeitem == '1'" style="color:#3AB881"  >( Free Item)</h5></td>
                                    <td width="10%" v-if="n.qty > 0"><h5 class="font-weight-regular">{{n.qty}}</h5></td>
                                    <td width="15%" v-if="n.qty > 0"><h5 class="font-weight-light">Qty.</h5></td>     
                                </tr>
                            </table>
                        </div>
                        <span class="font-weight-bold ma=7 bebas_18" >Add on</span>  
                        <br>
                            
                            <table class="mx-0" width="100%">
                                <tr  v-for="(item , index) in addonSort" :key="index" >            
                                    <td width="75%" ><h5 class="font-weight-light">{{item.itemname}} on  {{moment(item.date_adn).format('dddd , MMMM Do')}}</h5></td>
                                    <td width="10%" ><h5 class="font-weight-regular">{{item.price}}</h5></td>
                                    <td width="15%" ><h5 class="font-weight-light">THB.</h5></td>  
                                    <td><v-icon class="mr-2" @click="del_prod(item.itemcode , item.seq , item.date_adn )">delete</v-icon></td>   
                                </tr>
                            </table>
                        <br>    
                        <center><span class="font-weight-bold ma=7 bebas_18" v-if="this.item_price_total> 0" >
                            Total : {{this.item_price_total}} THB.</span></center>
                    </v-container>     
                    <v-container>    
                        <v-divider  class="text-center mx-5" ></v-divider>
                        <br>
                        <div class="font-weight-light ml-5" >
                            Delivery :
                            <v-radio-group
                                v-model="deli_at" row 
                                >
                                <v-radio v-if="deli_at_show_work"
                                    @click="show_add('work')"
                                    label="Work"
                                    value="work"
                                    color="black"
                                ></v-radio>
                                <v-radio v-if="deli_at_show_home"
                                @click="show_add('home')"
                                    label="Home"
                                    value="home"
                                    color="black"
                                ></v-radio>
                            </v-radio-group>
                            <span class="font-weight-light ml-5" >{{deli_add}} </span>
                        </div>
                    </v-container>
                    <v-container>
                        <v-text-field  class="text-center mx-7"
                            v-model="customer.Allergies"
                            label="Allergies"/>  
                        <v-text-field  class="text-center mx-7"
                            v-model="customer.remark"
                            label="Remark"/>  
                    </v-container>
                    <div class="text-center my-3">
                        <v-btn class="font-weight-bold" tile color="black" dark 
                            @click="confirm_order" >Confirm Order</v-btn>
                    </div>
                    <br>
                    <h5 style="color:red" class="ma-8" >** Minimum order of 2 items/day</h5>
                    <br><br>
            </v-card>
        </v-dialog>   

        <v-dialog v-model="dialog_l" width="500" >
            <v-card>
                <v-container>    
                    <center>
                        <h4 class="Aktiv_A" >Please Wait</h4>
                        <pulse-loader></pulse-loader>
                    </center>
                </v-container>  
             </v-card>
        </v-dialog>


        <v-dialog v-model="dialog_c" width="500" >
            <v-card>
                <v-container>    
                    <h3 class="text-center ma-5" >Your line name is {{$store.getters.get_user}}</h3>
                    <v-divider></v-divider>
                </v-container>  
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>       
                       <h3>Please fill your information</h3>
                        <v-text-field
                            v-model="customer_add.tel"
                            label="tel"
                            :rules="otherRules" 
                            required />   
                        <v-text-field
                            v-model="customer_add.email"
                            label="email"
                            :rules="otherRules" 
                            required />         
                        <h3>Address</h3>    

                        <v-radio-group
                            v-model="deli_at" row 
                            >
                            <v-radio 
                                @click="show_add('work')"
                                label="Work"
                                value="work"
                                color="black"
                            ></v-radio>
                            <v-radio  
                            @click="show_add('home')"
                                label="Home"
                                value="home"
                                color="black"
                            ></v-radio>
                        </v-radio-group>

                        <v-text-field
                            v-model="customer_add.address1"
                            label="Address1"
                            :rules="otherRules"  
                            required />
                        <v-text-field
                            v-model="customer_add.address2"
                            label="Address2"
                            :rules="otherRules"  
                            required />
                        <v-text-field
                            v-model="customer_add.zipcode"
                            label="zipcode"
                            :rules="otherRules" 
                            required />                          
                        <h3>Ohter Information</h3>       
                        <v-text-field
                            v-model="customer_add.Allergies"
                            label="Allergies"/>     
                        <v-text-field
                            v-model="customer_add.remark"
                            label="Remark"/>  
                        <v-btn class="font-weight-light" tile :disabled="!valid"
                            @click="add_cust_detail">
                            submit
                        </v-btn>
                        <v-btn class="font-weight-light" tile color="black" dark   
                        :disabled="true"
                        @click.native="clear">clear</v-btn>
                    </v-form>
                </v-container>
             </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios"
import moment from 'moment'
import _ from 'lodash';
//import mainorder from "@/components/main_order"
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'



export default {
    name: "cart_comp",
    created : function () {
        this.moment = moment;
    },
    computed: {
        addonSort() {
        return _.orderBy(this.ary_add_addon, 'date_adn' ); 
        }
    },
    components:{
        //mainorder,
        PulseLoader,
    },
    data() {
        return {
            item_in_cart : '',
            dialog_s: false ,
            dialog_c: false ,
            dialog_l: false,
            deli_add:'',
            deli_at : 'work',
            deli_at_show_work : true ,
            deli_at_show_home : true ,
            item_price_total : 0 ,
            items :[],
            items_ad :[],
            time_cus : 11 ,
            ary_add_addon : [] ,
            order_gen_g : "" ,
            total_point_g : 0 ,
            sync_oeder_ok : false,
            customer:{
                code: '',
                Allergies:'',
                remark:'',
            }, 
            customer_chk : '',
            customer_add :{
                code: '',
                name: '',
                new_pass1:'',
                new_pass2:'',
                address1:'',
                address2:'',
                zipcode:'',
                tel:'',
                email:'',
                Allergies:'',
                remark:'',
                latitude:'',
                longitude:'',
            },    
            codeRules: [
            v => !!v || 'This field is required',
            ],
            nameRules: [
            v => !!v || 'This field is required',
            ],        
            otherRules: [
            v => !!v || 'This field is required',
            ],
            value: String,
        }
    },
    methods :{
        open() {

            this.items = this.$store.getters.get_items
            this.ary_add_addon = this.$store.getters.get_ary_add_addon
            this.customer_add.code = this.$store.getters.get_user_code

            console.log( " addon -->  " + JSON.stringify(this.ary_add_addon ) )
            
            this.check_over_time()
            
            //this.dialog_s = true 

            let ii = 0;
            while (ii < this.items.length) {

                this.get_free_by_promo( this.items[ii].date )
                ++ii;
            }

            let date_del = ""    
            let i = 0;
            while (i < this.items.length) {
                if (this.items[i].tqty < 2 ) {      
                    
                    date_del = this.items[i].date  
                    var j= 0;
                    while (j < this.ary_add_addon.length) {
                        if (this.ary_add_addon[j].date_adn === date_del) {      
                            this.ary_add_addon.splice(j, 1);
                        } 
                        ++j;
                    }  
                } 
                ++i;
                
            }
            this.item_price_total = 0
            var k= 0;
            while (k < this.ary_add_addon.length) {
                this.item_price_total = this.item_price_total + parseInt(this.ary_add_addon[k].price)
                ++k;
            }

            this.customer_add_comp () 

            /*
            if ( this.$store.getters.get_create_user =='Y' ){
                this.dialog_c = true
            }else{
                this.get_address()
                this.dialog_s = true    
            }
            */

        },
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },
        make_event_id(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
        }
        return Date.now()+result;
        },
        del_prod( code , seq , date_adn ){

            let ary_addon = this.$store.getters.get_ary_addon
            let edit_ary_addon = ary_addon[seq]

            let strArray01 = []
            const requiredIndex = this.ary_add_addon.findIndex(el => {
                return el.itemcode === code;
            });

            this.ary_add_addon.splice(requiredIndex, 1);
            this.$store.dispatch('ac_ary_add_addon',  this.ary_add_addon )

            var j=0
            while ( j < edit_ary_addon.length ){

                if ( edit_ary_addon[j] != date_adn ){
                    strArray01.push(edit_ary_addon[j])
                }
              ++j
 
            }

            ary_addon[seq] = strArray01
            this.$store.dispatch('ac_ary_addon',  ary_addon )
            
            this.item_price_total = 0
            var k= 0;
            while (k < this.ary_add_addon.length) {
                this.item_price_total = this.item_price_total + parseInt(this.ary_add_addon[k].price)
                ++k;
            }

        },
        confirm_order ( ) {
            if ( this.check_order() ){
                    
                    var d = new Date()
                    var n = d.getDate()+d.getTime()
                    this.order_no = this.$store.getters.get_user.substring(0,8) + n  
                    this.dialog_s = false
                    this.dialog_l = true

                    if ( this.item_price_total > 0 ){
                        this.order_encode()
                        this.send_order_temp()
                        this.send_allergies()
                        window.location.href = "https://api.plantifulbkk.shop/addon_pay.php?addonamt=" + this.item_price_total
                        +"&point="+this.total_point_g+"&id="+this.order_no+"&scode="+this.$store.getters.get_user_code
                       
                        //+"&line_id_n="+"&line_name_n="
                    
                    }else{
                        this.order_encode()
                        this.send_order()

                        
                    }
                }
        },
         add_cust_detail(){

            if ( this.$refs.form.validate() )
                {

                    axios.get("https://ws.plantifulbkk.shop/post_edit_customer_address.php",{
                        params: {
                            scode: this.customer_add ,
                            sdeli: this.deli_at ,
                        }
                    }).then(result=>{
                            console.log(result.data)
                            this.$store.dispatch('set_create_user_ac', 'N' )
                            this.dialog_c = false
                            this.get_address()
                            //this.dialog_s = true  
                        })
                    .catch(function (error) {
                            alert("Error : Cannot Edit customer !!")
                            console.log(error)
                    })
                }else{
                    alert('Please fill out this form.  ')
                }

        },
        order_encode(){

            let add_items = {}
            let json_output = {}
            let order_gen = "["
            let order_length = 0 
            let total_point = 0           
            let i1 = 0
            let j1 = 0
            for (i1 = 0; i1 < this.items.length ; i1++) {
                if ( this.items[i1].tqty > 0  ){
                    for (j1 = 0; j1 < this.items[i1].order.length; j1++) {
                        if ( this.items[i1].order[j1].qty > 0 ){
                            order_length = order_length + 1
                            if ( this.items[i1].order[j1].freeitem == '0' ){
                                total_point = total_point + parseInt(this.items[i1].order[j1].qty)
                            }

                        }
                    }
                }
            }
            this.total_point_g  = total_point
            let i = 0
            let j = 0
            let k = 0
            for (i = 0; i < this.items.length ; i++) {
                if ( this.items[i].tqty == 0  ){
                    console.log("" + this.items[i].date + "is zero")
                }else{

                    for (j = 0; j < this.items[i].order.length; j++) {

                        if ( this.items[i].order[j].qty > 0 ){

                            add_items.tran_idi = this.makeid(20) 
                            add_items.cus_code = this.$store.getters.get_user_code
                            add_items.date1 = this.items[i].date
                            add_items.p_code = this.items[i].order[j].code
                            add_items.qty = this.items[i].order[j].qty
                            add_items.freeitem = this.items[i].order[j].freeitem                            
                            add_items.price = 0
                            add_items.id = this.order_no
                            add_items.allergies = this.customer.Allergies
                            add_items.remark = this.customer.remark
                            add_items.deli_add = this.deli_at
                            add_items.rang_week = ""
                            
                            json_output = add_items
                            k = k + 1
                            order_gen = order_gen + JSON.stringify(json_output)
                            if ( order_length == k ){
                                console.log(k)
                            } else {
                                order_gen = order_gen + ','
                            }
                        }
                    }

                }   

            }    
            // ad-on saction
            if ( this.ary_add_addon.length > 0 ){
                order_gen = order_gen + ','
            }
            k = 0

            for (j = 0; j < this.ary_add_addon.length; j++) {

                    add_items.tran_idi = this.makeid(20) 
                    add_items.cus_code = this.$store.getters.get_user_code
                    add_items.date1 = this.ary_add_addon[j].date_adn
                    add_items.p_code = this.ary_add_addon[j].itemcode
                    add_items.qty = 1
                    add_items.price = this.ary_add_addon[j].price
                    add_items.id = this.order_no
                    add_items.allergies = this.customer.Allergies
                    add_items.remark = this.customer.remark
                    add_items.deli_add = this.deli_at
                    add_items.rang_week = ""

                    json_output = add_items
                    k = k + 1
                    order_gen = order_gen + JSON.stringify(json_output)

                    if ( this.ary_add_addon.length == k ){
                        console.log(k)
                    } else {
                        order_gen = order_gen + ','

                    }

            }
            order_gen = order_gen + ']'
            this.order_gen_g =  order_gen   
            //------
            console.log('order_gen - > ' +  order_gen)
        },
        async send_order(){
            await axios.get("https://ws.plantifulbkk.shop/post_add_order_c.php",{
                    params: {
                        scode: this.$store.getters.get_user_code ,
                        sorder: this.order_gen_g ,
                        point: this.total_point_g ,
                        event_id : this.make_event_id(5) ,
                    }
                }).then(result=>{
                        console.log(result.data)
                        this.dialog_l = false
                        if ( result.data.includes('error') )  {
                            alert("Unsuccessfully order. Please contact admin. ")
                        }else{
                            this.send_allergies()
                            this.show_suscess()
                        }

                        this.ary_add_addon = [] 
                        this.get_credit()
                        this.dialog_s = false
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })
        },     
        send_order_temp(){
            axios.get("https://ws.plantifulbkk.shop/post_add_order_c_temp.php",{
                    params: {
                        scode: this.$store.getters.get_user_code ,
                        sorder: this.order_gen_g ,
                        point: this.total_point_g ,
                        event_id : this.make_event_id(5)  ,
                    }
                }).then(result=>{
                        console.log('<-->'+result)
                        this.dialog_s = false
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })
        },          
        send_allergies(){

            axios.get("https://ws.plantifulbkk.shop/post_edit_allergies.php",{
                    params: {
                        scode: this.customer  ,
                    }
                }).then(result=>{
                        console.log('al is = '+result.data)
                    })
                .catch(function (error) {
                        console.log('<-->'+error)
                    })

        },
        show_suscess(){
            this.$store.dispatch('ac_order_no', this.order_no ) 
            this.$store.dispatch('set_use_point', 0 ) 
            this.$router.push('/confirm')
            /*let toast = this.$toasted.show("Your Order number is "+this.order_no, { 
                theme: "outline", 
                position: "top-right", 
                duration : 2000
            });
            toast.show()
            */
        },
        close_order_detail(){
            this.dialog_s = false
        },
        customer_add_comp () {

        axios.get('https://ws.plantifulbkk.shop/get_customer_address_chkt.php',{
            params: {
                scode: this.$store.getters.get_user_code ,
            }
        }).then(response => {

            let res = response.data 
            let res3 = res.split(";");

            if ( res3[0]  == 0 ){
                this.$store.dispatch('set_create_user_ac','Y')
                this.customer_add.tel = res3[1]
                this.customer_add.email = res3[2]
                this.dialog_c = true
            }else{
                this.get_address()
                //this.dialog_s = true 

            }
        });
      },
        check_order(){
            
            console.log(this.items)

            if ( this.ret_chk_tot() ){
                
                let toast = this.$toasted.show("Your cart is empty!!", { 
                theme: "outline", 
                position: "top-right", 
                duration : 1000
                });
                toast.show()
                
                return false

            }

            if ( this.ret_chk(1) ){
                
                let toast = this.$toasted.show("Please select a minimum of 2 MEALS per day.", { 
                theme: "outline", 
                position: "top-right", 
                duration : 1000
                });
                toast.show()

                return false

            }

            return true
        },
        ret_chk( pnt ){
            for (var i = 0; i < this.items.length; i++) {
                if ( this.items[i].tqty ==  pnt ){
                    return true
                }
            }
            return false
        },
        ret_chk_tot(){
            var tot = 0
            for (var i = 0; i < this.items.length; i++) {
                tot = tot + this.items[i].tqty
            }
            if ( tot == 0){
                return true
            }
            return false
        },
        get_free_by_promo( item_date){

            var ind = '' 
            var free_code = ''
            var cnt_free_sku = 0
            var num_free = 0
            var sum_sell_qty = 0 
            var addr_free_item = 0

            for (var i = 0; i < this.items.length; i++) {
                    if ( this.items[i].date == item_date ){
                        ind = i 
                    }
                }

            for (var j = 0; j < this.items[ind].order.length; j++) {
                if ( this.items[ind].order[j].freeitem  == '1' ){
                    free_code = this.items[ind].order[j].code
                    num_free = this.items[ind].order[j].sell_to_free_qty
                    addr_free_item = j
                    this.items[ind].order[j].qty = 0
                    cnt_free_sku = cnt_free_sku + 1
                }
            }

            if ( cnt_free_sku == 0){
                return
            }

            for (var k = 0; k < this.items[ind].order.length; k++) {
                if ( this.items[ind].order[k].freeitem  == '0' ){
                      sum_sell_qty = sum_sell_qty + parseInt(this.items[ind].order[k].qty)
                }
            }


            if ( sum_sell_qty >= num_free ){
                this.items[ind].order[addr_free_item].qty = 1
                console.log(free_code)
            }

        },

        check_over_time(){

            var date = new Date();
            date = this.convertTZ(date, "Asia/Bangkok")
            date.setDate(date.getDate() + 1);
            //var time_now = parseInt(date.getHours())

            for (var i = 0; i < this.items.length; i++) {
                if ( this.$moment(this.items[i].date).format("YYYY-MM-DD ")+"12:00" < this.$moment(date).format("YYYY-MM-DD HH:MM") ){
                            for (var j = 0; j < this.items[i].order.length; j++) {
                                if ( this.items[i].order[j].qty > 0 ){
                                    axios.get("https://ws.plantifulbkk.shop/post_product_temp.php",{
                                            params: {
                                                scode: this.$store.getters.get_user_code ,
                                                date: this.items[i].date ,
                                                p_code: this.items[i].order[j].code  ,
                                                qty: - this.items[i].order[j].qty  ,
                                                tran_idi : this.makeid(20) ,
                                            }
                                        })
                                }
                                this.$store.dispatch('set_use_point', this.$store.getters.get_use_point - this.items[i].order[j].qty ) 
                                this.items[i].order[j].qty = 0
                                this.items[i].tqty = 0
                            }
                        }
                    //if (  this.$moment(this.items[i].date).format("DD") <= date.getDate()-1
                    //      | ( this.$moment(this.items[i].date).format("DD") == date.getDate() & time_now > this.time_cus ) ){ }
            }
            console.log (  this.items )
        }, 
        convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        },
        show_add( item){
            if ( item == 'work' ){
                this.deli_add = this.deli_add_1 
            }
            if ( item == 'home' ){
                this.deli_add = this.deli_add_2 
            }                
        },
        
        get_address () {
            axios.get("https://ws.plantifulbkk.shop/get_customer_address.php",{
                params: {
                    scode: this.$store.getters.get_user_code ,
                }
            }).then(result=>{
                    console.log(result.data)
                    let res = result.data
                        let res3 = res.split(";");
                        this.deli_add_1 = res3[0] + res3[2]
                        this.deli_add_2 = res3[1] + res3[2]
                        if ( res3[0].length > 20 ){
                            this.deli_at_show_work = true
                        }else{
                            this.deli_at_show_work = false
                            }
                        if ( res3[1].length > 20){
                            this.deli_at_show_home = true
                        }else{
                            this.deli_at_show_home = false
                        }

                        if ( this.deli_at == 'work'){
                            this.deli_add = res3[0]+ res3[2]
                        }   
                        if ( this.deli_at == 'home'){
                            this.deli_add = res3[1]+ res3[2]
                        }
                        this.customer.Allergies = res3[3]
                        this.customer.remark = res3[4]

                        this.dialog_s = true 

                })
            .catch(function (error) {
                    alert("Error")
                    console.log(error)
                })
        },



    },
}
</script>