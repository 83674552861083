<template>
    <div>
    <br><br>
    <center><h2>Ordered Calendar</h2></center>
    <br>
    <v-row justify="center">
        <v-icon @click="pre_month()" >navigate_before</v-icon>
        <h4 class="Aktiv_A" > {{firstDay}} - {{lastDay}}</h4>
        <v-icon @click="next_month()" >navigate_next</v-icon>
    </v-row>
    <br>
      <v-sheet class="ma-5" height="600">
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          :event-text-color="getEventtextColor"
          @change="getEvents"
          @click:event="showEvent"
          @click:more="viewDay"


        ></v-calendar>
      </v-sheet>

        <v-dialog v-model="dialog_s"  width="600" >
            <v-card>
                <v-card-title class="headline">Date : {{date_view}} </v-card-title> 
                <br>
                  <v-card-text>
                        <div>                 
                            <table width="100%">
                                    <tr>            
                                    <td width="20%" ><h4></h4></td>
                                    <td width="60%" ><h4>Item name</h4></td>
                                    <td width="10%" ><h4 class="font-weight-regular"><center>Qty</center></h4></td>
                                    <td width="10%" ><h4 class="font-weight-light"></h4></td>     
                                </tr>
                                <tr  v-for=" n in order_hist_day" :key = "n" >            
                                    <td width="20%" >

                                        <v-img :src="url + n.image" contain  height="100"  max-width="100"  >
                                        </v-img>

                                    </td>
                                    <td width="60%" ><h4 class="font-weight-light">{{n.name}}</h4></td>
                                    <td width="10%" ><h4 class="font-weight-regular"><center>{{n.qty}}</center></h4></td>
                                </tr>
                            </table>               
                        </div>           
                </v-card-text>
                <br>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import axios from "axios"
import moment from 'moment'
export default {
    name: "calendar_comp",
    
    data: () => ({
      type: 'month',
      mode: 'stack',
      weekday: [1, 2, 3, 4, 5],
      value: '',
      events: [],
      order_hist: [],
      order_hist_day :[],
      dialog_s : false , 
      colors: [ 'green' , 'grey darken-1'],
      month : ["January","February","March","April","May","June","July"
              ,"August","September","October","November","December"],
      date : new Date(), 
      firstDay :'',
      lastDay : '',
      curr_month : 1 ,
      y :'',
      m : '',
      mn : '',
      date_view : '',
      url : "https://ws.plantifulbkk.shop/images/" ,

    }),
    beforeCreate() {

      
    },
    methods: {
      getEvents () {

        this.set_month()

      },
      getEventColor (event) {
        return event.color
      },
      getEventtextColor (event) {
        return event.textcolor
      },
      next_month () {
        
        this.$refs.calendar.next()
        ++ this.curr_month
        this.set_month()

      },
      pre_month () {

        this.$refs.calendar.prev()
        -- this.curr_month
        this.set_month()

      },
      set_month () {
             
        this.y = this.date.getFullYear()
        this.m = this.date.getMonth() + this.curr_month - 1
        this.mn = this.date.getMonth()

        this.firstDay = moment(new Date(this.y, this.m, 1 )).format('YYYY-MM-DD')
        this.lastDay = moment(new Date(this.y, this.mn + this.curr_month, 0)).format('YYYY-MM-DD')

        this.read_data()

      },
      showEvent ({ nativeEvent, event }) {

        console.log(  event.start , nativeEvent )
          this.date_view = event.start 
          this.read_data_h()
          this.dialog_s = true  
 
      },
      viewDay({ date }){

          this.date_view = date
          this.read_data_h()
          this.dialog_s = true  

      },
        read_data(){
            const events = []
            axios.get("https://ws.plantifulbkk.shop/get_calendar_detail.php",{
                        params: {
                            scode: this.$store.getters.get_user_code ,
                            firstDay : this.firstDay ,
                            lastDay : this.lastDay ,
                        }
                    }).then(result=>{
                  this.order_hist =  result.data
                  for (var i = 0; i < this.order_hist.length; i++) {
                    events.push({
                      name: this.order_hist[i].name ,
                      start: this.order_hist[i].date ,
                      end: this.order_hist[i].date ,
                      color: 'White',
                      textcolor: 'black',
                    })
                  }

                  this.events = events
            })
        },
        async read_data_h(){

            await axios.get("https://ws.plantifulbkk.shop/get_hist_detail.php",{
                        params: {
                            scode: this.$store.getters.get_user_code ,
                            stype: this.date_view
                        }
                    }).then(result=>{
                this.order_hist_day =  result.data
                console.log(result.data)
            })
        },

    },
  }
</script>