<template>
    <div>
        <registerpage/>
    </div>
</template>

<script>
import registerpage from "@/components/register_page.vue";

export default {
    name: 'register_view',
    components: {
    registerpage,
  },
  
}
</script>

<style>

</style>