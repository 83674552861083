<template>
    <div>
        <orderpage/>
    </div>
</template>

<script>
import orderpage from "@/components/sel_order.vue";

export default {
    name: 'order_view',
    components: {
    orderpage,
  },
  
}
</script>

<style>

</style>