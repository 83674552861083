<template>
    <div>
        <v-btn class="font-weight-regular ml-15 mt-5" tile color="black" dark 
            @click="btn_back" >Back</v-btn>

        <div class="text-center">
            <br><br>
            <h1 class="font-weight-light display-1">Our menu</h1>
        </div>
        <v-layout row >
            <v-flex xs12 md12 ma-2 ma-md-15>
                <v-container fluid grid-list>
                <h2 class="ma-3 font-weight-light"  >Meal</h2>
                <v-layout row wrap >
                    <v-flex v-for="item in items" :key="item.code" xs6 md3>
                        <v-card class="text-center ma-3" color="white" 
                        @click="dispItem(item.name , item.descrip , item.image )"  >
                            <img :src="url + item.image"
                            class="image" alt="lorem" width="90%" height="90%" >
                            <v-dialog v-model="dialog" width="500" >
                                <v-card>
                                    <v-card-title class="headline">{{sel_item_name}}</v-card-title>
                                    <v-card-text>{{sel_item_descrip}}</v-card-text>
                                    <v-row justify="center" class="text-center">
                                        <img :src="url + sel_item_image" class="image" alt="lorem" width="90%" height="90%" >
                                    </v-row>
                                    <v-row class="mx-7" >
                                        <h4>Ingredients</h4>
                                        <h5 class="font-weight-light"  >
                                            Black Beans, Kidney Beans, Quinoa, Tomatoes, Onion, Textured Veg Soy Protein, Corn Kernels, Tomato Paste, Coconut Aminos, Nutritional Yeast, Lemon Juice, Spring Onions, Ground Cumin, Olive Oil, Pepper, Sea Salt, Contains Gluten, Contains: Soy, Wheat (Gluten)
                                        </h5>
                                    </v-row> 
                                    <br>
                                </v-card>
                            </v-dialog>
                        </v-card> 
                        <v-row justify="center" class="text-center ma-7 "
                                style="height: 20px;">
                            <h4 class="text-center font-weight-regular" v-line-clamp="2">
                                {{item.name}}</h4>  
                        </v-row>
                        <v-row  justify="center" class="text-center ma-7 "
                                style="height: 40px;">
                            <p class="font-weight-light"  v-line-clamp="2">
                                {{item.descrip}}</p>
                        </v-row>
                        <v-row justify="center" >
                            <v-btn text small color="black" dark 
                            @click="addItem(item.code)" >
                                <v-icon>remove</v-icon>
                            </v-btn>

                                <h4>0</h4>

                            <v-btn text small color="black" dark 
                            @click="addItem(item.code)" >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </v-row>
                        <br>
                    </v-flex>
                </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </div>     
</template>

<script>
import axios from "axios"
export default {
        name: "sel_order",
    components:{

    },
    mounted(){
        let set_seq = this.$store.getters.cur_seq
        let get_url = ""
        
        if ( set_seq.cur_seq == "S1" ){
            get_url = "https://ws.plantifulbkk.shop/get_snk.php"
        }else{
            get_url = "https://ws.plantifulbkk.shop/get_meal.php"    
        }

        axios.get(get_url).then(result=>{
            this.items = result.data
        })
    },
    data() {
        return {
            url : "https://ws.plantifulbkk.shop/images/" ,
            items :[],
            sel_item_name : "",
            sel_item_descrip : "",
            sel_item_image : "",
            dialog: false,
        }
    },
    methods: {
    
        dispItem (itemname , itemdescrip , itemimage) {
            this.sel_item_name = itemname
            this.sel_item_descrip = itemdescrip
            this.sel_item_image = itemimage
            this.dialog = true
        },
        addItem (itemcode) {
            let set_curr = this.$store.getters.cur_food 
            let set_seq = this.$store.getters.cur_seq
            
            axios.get("https://ws.plantifulbkk.shop/post_day_item.php",{
                        params: {
                            scode: this.$store.getters.get_user_code ,
                            set_seq : set_seq.cur_seq ,
                            set_curr : set_curr.cur_food ,
                            itemcode : itemcode ,
                        }
                    }).then(result=>{
                            console.log(result.data)

                            if ( this.$store.getters.get_meal_1.meal_1 == "" 
                                &&  this.$store.getters.get_meal_2.meal_2 == "" 
                                && this.$store.getters.get_snk_1.snk_1 == ""){

                                    axios.get("https://ws.plantifulbkk.shop/post_credit.php",{
                                        params: {
                                            scode: this.$store.getters.get_user_code ,
                                            scredit: -1 ,
                                        }
                                    }).then(result=>{
                                            console.log(result.data)
                                            this.$router.push("/main")
                                        })
                                    .catch(function (error) {
                                            alert("Error")
                                            console.log(error)
                                        })

                            }

                            this.$router.push("/main")
                        })
                    .catch(function (error) {
                            alert("Error")
                            console.log(error)
                        })

        },
        btn_back(){
        this.$router.push('/main')
        },
    },
}
</script>