<template>
    <div class="text-center" >
      <br><br><br>
        <p>Thankyou for your order</p>
        <br>
        <h5>Your Order number is</h5>
        <h5>{{this.$store.getters.get_order_no}}</h5>
        <br><br>
        <v-btn tile @click="go_main"   >
            Back to Order Page
        </v-btn>   

    </div>
</template>

<script>
export default {
    name: "confirm_page_comp",
    components:{

    },
  data() {
    return {
        value: String,
    }
  },
  mounted() {
      this.$store.dispatch('ac_show_cart', 0 )
  },
  methods :{
      go_main(){
          this.$router.push('/main')
      }


  },
}
</script>

<style>

p {
  font-size: 1.2em;
}
@media (min-width: 48rem) {
  p {
    font-size: 2em;
  }
}

</style>