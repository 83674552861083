<template>
    <div>
        <orderpages/>
    </div>
</template>

<script>
import orderpages from "@/components/sel_order_s.vue";

export default {
    name: 'order_s_view',
    components: {
    orderpages,
  },
  
}
</script>

<style>

</style>