<template>
    <div>
        <select_reg/>
    </div>
</template>

<script>
import select_reg from "@/components/select_reg_c.vue";

export default {
    name: 'select_reg_view',
    components: {
    select_reg,
  },
  
}
</script>

<style>

</style>