<template>
    <div>
        <changepass/>
    </div>
</template>

<script>
import changepass from "@/components/change_password.vue";

export default {


  name: 'change_password_view',
  components: {
    changepass,
  }
}
</script>