<template>
    <div>
        <loginpage/>
    </div>
</template>

<script>
import loginpage from "@/components/login_page.vue";

export default {
    name: 'login_view',
    components: {
    loginpage,
  },
  
}
</script>

<style>

</style>