import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user : "", 
    user_code : "",
    user_img : "",
    cur_add_food : '',
    cur_add_seq : '',
    your_credit : 0 ,
    your_exp_credit : 0 ,
    your_exp_title : '' ,
    your_allergies : '' ,
    your_remark : '' ,    
    meal_1 : '' ,
    meal_2 : '' ,
    snk_1 : '' ,
    create_user : '' ,
    use_point : 0 ,
    items :[],
    items_ad : [],
    ary_add_addon : [],
    order_no : '',
    show_cart : 0 ,
    type_of_action : '',
    ary_addon : [[],[],[],[],[],[],[],[],[],[],[],[]],
    pkg:'',
  },
    plugins: [
      createPersistedState({
        storage: {
          getItem: key => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: key => ls.remove(key)
        }
      })
    ],

  mutations: {
    setpkg (state, pkg) {
      state.pkg = pkg
    },
    setCur (state, cur_food) {
      state.cur_add_food = cur_food
    },
    setSeq (state, cur_seq) {
      state.cur_add_seq = cur_seq
    },
    set_your_credit (state, your_credit) {
      state.your_credit = your_credit
    },
    set_user_img (state, user_img) {
      state.user_img = user_img
    },    
    set_your_exp_credit (state, your_exp_credit) {
      state.your_exp_credit = your_exp_credit
    },
    set_your_exp_title (state, your_exp) {
      state.your_exp_title = your_exp
    },
    set_your_allergies (state, allergies_user) {
      state.your_allergies = allergies_user
    },
    set_your_remark (state, remark_user) {
      state.your_remark = remark_user
    },
    set_user (state, active_user) {
      state.user = active_user
    },
    set_user_code (state, active_user) {
      state.user_code = active_user
    },
    set_meal_1 (state, meal_1 ) {
      state.meal_1 = meal_1
    },
    set_meal_2(state, meal_2 ) {
      state.meal_2 = meal_2
    },
    set_snk_1 (state, snk_1 ) {
      state.snk_1 = snk_1
    },
    set_create_user( state , create_user ){
      state.create_user = create_user
    },
    set_use_point( state , use_point ){
      state.use_point = use_point
    },
    set_items( state , items ){
      state.items = items
    },
    set_items_ad( state , items_ad ){
      state.items_ad = items_ad
    },
    set_ary_add_addon( state , ary_add_addon ){
      state.ary_add_addon = ary_add_addon
    },
    set_order_no( state , order_no ){
      state.order_no = order_no
    },
    set_show_cart( state , show_cart ){
      state.show_cart = show_cart
    },
    set_ary_addon( state , ary_addon){
      state.ary_addon = ary_addon
    },
    set_type_of_action( state , type_of_action){
      state.type_of_action = type_of_action
    },
  },
  actions: {
    sel_pkg_ac ( context , pkg ) {
      context.commit('setpkg', pkg )
    },
    sel_cur_food ( context , cur_food ) {
      context.commit('setCur', cur_food )
    },
    sel_cur_seq ( context , cur_seq ) {
      context.commit('setSeq', cur_seq )
    },
    your_credit ( context , your_credit ) {
      context.commit('set_your_credit', your_credit )
    },
    your_exp_credit ( context , your_credit ) {
      context.commit('set_your_exp_credit', your_credit )
    },
    your_exp_title ( context , your_credit ) {
      context.commit('set_your_exp_title', your_credit )
    },
    your_exp_allergies ( context , your_allergies ) {
      context.commit('set_your_allergies', your_allergies )
    },
    your_exp_remark ( context , your_remark ) {
      context.commit('set_your_remark', your_remark )
    },
    set_user_ac ( context , active_user ) {
      context.commit('set_user', active_user )
    },
    set_user_ac_code ( context , active_user ) {
      context.commit('set_user_code', active_user )
    },
    set_user_img ( context , user_img ) {
      context.commit('set_user_img', user_img )
    },    
    keep_meal_1( context , meal_1 ) {
      context.commit('set_meal_1', meal_1 )
    },
    keep_meal_2( context , meal_2 ) {
      context.commit('set_meal_2', meal_2 )
    },
    keep_snk_1( context , snk_1 ) {
      context.commit('set_snk_1', snk_1 )
    },
    set_create_user_ac( context , create_user ){
      context.commit('set_create_user' , create_user)
    },
    set_use_point( context , use_point ){
      context.commit('set_use_point' , use_point)
    },
    ac_use_items( context , items ){
      context.commit('set_items' , items)
    },
    ac_items_ad( context , items_ad ){
      context.commit('set_items_ad' , items_ad)
    },
    ac_ary_add_addon( context , ary_add_addon ){
      context.commit('set_ary_add_addon' , ary_add_addon)
    },
    ac_order_no( context , order_no ){
      context.commit('set_order_no' , order_no)
    },
    ac_show_cart( context , show_cart ){
      context.commit('set_show_cart' , show_cart)
    },
    ac_ary_addon ( context , ary_addon ){
      context.commit( 'set_ary_addon', ary_addon )
    },
    ac_type_of_action ( context , type_of_action ){
      context.commit( 'set_type_of_action', type_of_action )
    },
  },
  modules: {
  },
  getters:{
    cur_pkg (state) {
      return state.pkg
    },
    cur_food (state) {
      return state.cur_add_food
    },
    cur_seq (state) {
      return state.cur_add_seq
    },
    your_credit (state) {
      return state.your_credit
    },
    your_exp_credit (state) {
      return state.your_exp_credit
    },
    your_exp_title (state) {
      return state.your_exp_title
    },
    your_allergies (state) {
      return state.your_allergies
    },
    your_remark (state) {
      return state.your_remark
    },
    get_user (state) {
      return state.user
    },
    get_user_img (state) {
      return state.user_img
    },
    get_user_code (state) {
      return state.user_code
    },
    get_meal_1 (state) {
      return state.meal_1
    },
    get_meal_2 (state) {
      return state.meal_2
    },
    get_snk_1 (state) {
      return state.snk_1
    },
    get_create_user (state){
      return state.create_user
    },
    get_use_point (state){
      return state.use_point
    },
    get_items (state){
      return state.items
    },
    get_items_ad (state){
      return state.items_ad
    },
    get_ary_add_addon (state){
      return state.ary_add_addon
    },
    get_order_no (state){
      return state.order_no
    },
    get_show_cart (state){
      return state.show_cart
    },
    get_ary_addon (state){
      return state.ary_addon
    },
    get_type_of_action (state){
      return state.type_of_action
    },

  }
})
