<template>
    <div class="text-center" >
      <br><br><br>
        <p>Customer Order Page</p>
        <v-layout row>
          <v-flex xs6 sm4 offset-xs3 offset-sm4>

                <v-container>

                  <v-btn block color="success" @click="login_with_line()" >      
                    <v-icon left class="mr-3" >
                      mdi-chat-outline
                    </v-icon>
                    <h2 class="a">Sign in with line</h2>
                  </v-btn>
                  <br>
                  OR

                  <form @submit.prevent="onSignin">
                    <v-layout row>
                      <v-flex xs12>
                        <v-text-field
                          name="Username"
                          label="Email"
                          id="username"
                          v-model="username"
                          type="text"
                          color="black" 
                          required></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs12>
                      <v-text-field
                          name="password"
                          label="Password"
                          id="password"
                          v-model="password"
                          color="black" 
                          :append-icon="value ? 'visibility' : 'visibility_off'"
                          @click:append="() => (value = !value)"
                          :type="value ? 'password' : 'text'"
                          required 
                      ></v-text-field>

                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs12>
                        <v-btn tile  color="black" dark type="submit"  >
                          <h2 class="a">Login</h2>
                          <span slot="loader" class="custom-loader">
                            <v-icon light>cached</v-icon>
                          </span>
                        </v-btn>                  
                      </v-flex>
                    </v-layout>
                  </form>
                  <br>
                  <div>
                    <v-row>
                      <v-col>
                        <h4 style="color:gray" @click="resg()"  >Sign up</h4>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <h4 style="color:gray" @click="forgot()"  >Forgot Password</h4>
                      </v-col>
                    </v-row>
                  </div>  
                </v-container>
          </v-flex>
        </v-layout>
    </div>
</template>

<script>

import axios from "axios"
import liff from "@line/liff";

export default {
    name: "login_page_comp",
    components:{

    },
  data() {
    return {
        username: '',
        password: '',
        event_id: '',
        uid:'',
        name:'',
        line_picture:'',
        pkg : '',
        url :'',
        value: String,
    }
  },
  created() {
    this.$store.dispatch('set_user_ac_code', '')
    this.initializeLiff_chk();
  },

  methods :{

    initializeLiff_chk: function () {

        liff.init({
        liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
        })
        .then(() => {
          // start to use LIFF's api
          if (liff.isLoggedIn()) {
              liff.getProfile()
              .then(profile => {

                this.name =  profile.displayName 
                this.uid = profile.userId
                this.line_picture = profile.pictureUrl

                this.check_line_regis( this.uid )

              })
              .catch((err) => {
                console.log('error', err)
              })            
              

              //me.$router.push({ name: 'login' })
            }else{
              console.log("")
            }
        })
        .catch((err) => {
          console.log(err);
        });

        },


    initializeLiff: function () {

      liff.init({
      liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
      })
      .then(() => {
        // start to use LIFF's api
        if (liff.isLoggedIn()) {

            liff.getProfile()
            .then(profile => {

              this.name =  profile.displayName 
              this.uid = profile.userId
              this.line_picture = profile.pictureUrl


              console.log(this.uid )  

              this.check_line_regis( this.uid )

            })
            .catch((err) => {
              console.log('error', err)
            })            
            

            //me.$router.push({ name: 'login' })
          }else{

            liff.login();

          }
      })
      .catch((err) => {
        console.log(err);
      });

      },

      logout: function () {

      this.$session.set('scode_ses', '' )    
      
      if (liff.isLoggedIn()) {
        liff.logout()
        this.$router.push({ name: 'home' })
      }
      },

    resg(){
      this.$router.push('/register')
    },
    login_with_line(){
      
            this.event_id = this.make_event_id(5)

            axios.get("https://ws.plantifulbkk.shop/post_event_log_g.php",{
                        params: {
                          scode: this.username ,
                          event_id : this.event_id ,
                          SESSION : '',
                          log : 'start..login.by.line' ,
                        }
                    }).then(result=>{

                      console.log(result)
            }) 

          //window.location.href = "https://api.plantifulbkk.shop/login_uselib.php?type=1&event_id="+this.event_id  

          this.initializeLiff();

    },

    check_line_regis( userId ){

      this.$session.start()
      console.log( ' session ' + this.$session.get('vue_pkg') )
      this.pkg = this.$session.get('vue_pkg')
      this.$session.set('vue_pkg', '' )

      axios.get("https://ws.plantifulbkk.shop/login_by_line.php",{
                      params: {
                          scode: userId ,                    
                      }
                  }).then(result=>{
                          //console.log('login_by_line -> '+result.data)
                          let res = result.data

                          if ( result.data.includes("incorrect") ){       
                            
                            axios.get("https://ws.plantifulbkk.shop/post_addLine_user.php",{
                                          params: {
                                            scode: this.uid ,
                                            sname : this.name ,
                                            spicture : this.line_picture,                                          
                                          }
                                      }).then(result=>{

                                        console.log(result)
                                        this.$store.dispatch('set_user_ac_code', result.data )
                                        this.$store.dispatch('set_create_user_ac', ''  )
                                        this.$store.dispatch('set_user_img', this.line_picture  )
                                        this.show_img = this.line_picture

                                        if ( this.pkg == '3' ||  this.pkg == '5' ||  this.pkg == '10' ||  this.pkg == '20' ){
                                          this.url = 'https://api.plantifulbkk.shop/pgk_pay.php?pkg='+this.pkg+'&scode='+this.$store.getters.get_user_code
                                          window.location.href = this.url
                                        }

                                        this.$router.push('/main')
                                        
                              }) 
                                            
                          
                            }else{

                              let res3 = res.split(","); 

                              this.$store.dispatch('set_user_ac_code', res3[0] )
                              this.$store.dispatch('set_create_user_ac', ''  )
                              this.$store.dispatch('set_user_img', res3[1]  )
                              this.show_img = res3[1] 

                              if ( this.pkg == '3' ||  this.pkg == '5' ||  this.pkg == '10' ||  this.pkg == '20'  ){
                                this.url = 'https://api.plantifulbkk.shop/pgk_pay.php?pkg='+this.pkg+'&scode='+this.$store.getters.get_user_code
                                window.location.href = this.url
                              }

                              this.$router.push('/main')
                              
                          }
                      })
                  .catch(function (error) {
                          alert("Error")
                          console.log(error)
              })

    },

    forgot(){
      this.$router.push('/forgot')
    },    
    onSignin () {

      axios.get("https://ws.plantifulbkk.shop/login.php",{
          params: {
              scode: this.username ,
              spassword : this.password ,
          }
      }).then(result=>{
              console.log(result.data)
              let res = result.data
              if ( result.data.includes("incorrect") ){
                  alert("Username or Password Incorrect !")
              }else{

                    let res3 = res.split("^");
                    this.event_id = this.make_event_id(5)

                    axios.get("https://ws.plantifulbkk.shop/post_event_log_g.php",{
                                params: {
                                  scode: res3[3] ,
                                  event_id : this.event_id ,
                                  SESSION : '',
                                  log : 'start..login.by.email' ,
                                }
                            }).then(result=>{

                              console.log(result)
                    }) 

                  this.$session.start()  
                  this.$session.set('scode_ses', res3[3] )    
                  sessionStorage.setItem("scode_storage", res3[3] )

                  console.log(  'scode_ses = ', this.$session.get('scode_ses') )
                  console.log(  'scode_storage = ', sessionStorage.getItem('scode_storage') )

                  this.$store.dispatch('set_user_ac',res3[0])
                  this.$store.dispatch('your_exp_allergies',res3[1])
                  this.$store.dispatch('your_exp_remark',res3[2])
                  this.$store.dispatch('set_user_ac_code', res3[3])
                  this.$store.dispatch('set_user_img', res3[4]  )
                  this.$store.dispatch('set_create_user_ac', 'N'  )
                  this.$router.replace("main");
              }
          })
      .catch(function (error) {
              alert("Error")
              console.log(error)
          })
    },

    make_event_id(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
    }
    return Date.now()+result;
    },

  },
}
</script>

<style>

p {
  font-size: 1.2em;
}
@media (min-width: 48rem) {
  p {
    font-size: 2em;
  }
}

h2.a {
  font-size: 0.8em;
  color: white;
}
@media (min-width: 48rem) {
  h2.a {
    font-size: 1.2em;
    color: white;
  }
}

</style>