<template>
    <div>
    <br>
    <center><h2 id="cus_head">Sign up</h2></center>
    <v-container grid-list-xs>
        <v-layout justify-center>
            <v-flex xs6>
                <h5 style="color:red" >* This field is required </h5>
                <v-form ref="form" v-model="valid" lazy-validation>
                <h5><v-text-field
                    v-model="customer.email"
                    label="* email for login"
                    :rules="otherRules" 
                    required />
                <v-text-field
                    v-model="customer.new_pass1"
                    label="Enter New Password"
                    :append-icon="value ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    :rules="passRules"  
                    required 
                ></v-text-field>
                <v-text-field
                    v-model="customer.new_pass2"
                    label="Confirm New Password"
                    :append-icon="value ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    :rules="passRules"  
                    required 
                ></v-text-field>
                <br>    
                <v-text-field
                    v-model="customer.name"
                    :rules="nameRules"                    
                    label="* Name"
                    required />
                <v-text-field
                    v-model="customer.tel"
                    label="* tel"
                    :rules="otherRules" 
                    required /></h5>    
                <h3>Work Address</h3>    
                <h5><v-text-field
                    v-model="customer.address1"
                    label="* Address1"
                    :rules="otherRules"  
                    required />
                <v-text-field
                    v-model="customer.address2"
                    label="* Address2"
                    :rules="otherRules"  
                    required />
                 <v-text-field
                    v-model="customer.zipcode"
                    label="* zipcode"
                    :rules="otherRules" 
                    required /> </h5>              
                <h3>Home Address</h3>    
                <h5><v-text-field
                    v-model="customer.h_address1"
                    label="* Address1"
                    :rules="otherRules"  
                    required />
                <v-text-field
                    v-model="customer.h_address2"
                    label="* Address2"
                    :rules="otherRules"  
                    required />
                 <v-text-field
                    v-model="customer.h_zipcode"
                    label="* zipcode"
                    :rules="otherRules" 
                    required /></h5>       
                <h3>Ohter Information</h3>       
                <h5><v-text-field
                    v-model="customer.Allergies"
                    label="Allergies"/>     
                <v-text-field
                    v-model="customer.remark"
                    label="Remark"/></h5>  
                <center>
                    <v-btn class="font-weight-light" tile :disabled="!valid"
                        @click="edit_customer">
                        submit
                    </v-btn>
                </center>    
                <v-btn class="font-weight-light" tile color="black" dark   
                :disabled="true"
                @click.native="clear">clear</v-btn>
                </v-form>
            </v-flex>
        </v-layout>   
    </v-container>
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "customer_info",
    components:{

    },
  data() {
    return {
            valid: true,
            type_of_action : '',
            customer:{
                code: '',
                name: '',
                email:'',
                new_pass1 : '',
                new_pass2 : '', 
                address1:'',
                address2:'',
                zipcode:'',
                tel:'',
                Allergies:'',
                remark:'',
                latitude:'',
                longitude:'',
                h_address1:'',
                h_address2:'',
                h_zipcode:'',
                h_latitude:'',
                h_longitude:'',
            },    
            pass_chk : 2 ,
            codeRules: [
            v => !!v || 'This field is required',
            ],
            nameRules: [
            v => !!v || 'This field is required',
            ],        
            passRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 6) || 'This field must be more than 6 characters'
            ],
            otherRules: [
            v => !!v || 'This field is required',
            ],
            value: String,
        }
    },
    created () {
    },
    mounted() {
    },

  beforeCreate() {

      let uri = window.location.search.substring(); 
      let params = new URLSearchParams(uri);
      this.type_of_action = params.get("pkg");
  },

    methods :{
        clear_pass(){
            this.pass_chk = this.pass_chk - 1
            this.customer.new_pass1 = ''
            this.customer.new_pass2 = '' 
        },
        edit_customer(){
            if ( this.customer.new_pass1 == '' || this.customer.new_pass2 == '' ){
                alert("Password Can't Blank !!")
                this.clear_pass()
                return;
            }
            if ( this.customer.new_pass1 != this.customer.new_pass2 ){
                alert("Password Don't Match!!")
                this.clear_pass()
                return;
            }
            if ( this.$refs.form.validate() )
            {
                axios.get("https://ws.plantifulbkk.shop/post_regist_customer.php",{
                    params: {
                        scode: this.customer ,
                    }
                }).then(result=>{
                        console.log(result.data)
                        if ( result.data == 'email_dup' ) {
                            alert("E-mail Duplicate. please use other E-mail ")
                        }else{
                            this.$refs.form.reset()
                            alert("Create Account  Complete")

                            console.log( " result -> "+result.data , " type_of_action -> "+this.$store.getters.get_type_of_action , "scode -> "+this.event_id )

                            if ( this.$store.getters.get_type_of_action > 0  ){

                                window.location.href = "https://api.plantifulbkk.shop/pgk_pay.php?pkg="+this.$store.getters.get_type_of_action+"&scode="+result.data  


                            }else{

                                this.$store.dispatch('set_user_ac',this.customer.name)
                                this.$store.dispatch('your_exp_allergies',this.customer.Allergies)
                                this.$store.dispatch('your_exp_remark',this.customer.remark)
                                this.$store.dispatch('set_user_ac_code', result.data )
                                this.$store.dispatch('set_user_img', '' )
                                this.$store.dispatch('set_create_user_ac', 'N'  )
                                this.$router.replace("/buycredit");
                           
                           }
                        }
                    })
                .catch(function (error) {
                        alert("Error : Cannot Create Account !!")
                        console.log(error)
                })
            }else{
                alert('Please fill out this form.  ')
            }
        },
    }
}
</script>

<style>

</style>