<template>
    <div>
        <br><br>
        <h1 class="text-center font-weight-regular ">Order History</h1>
        <v-container grid-list-xs>
                <v-data-table
                    :headers="headers"
                    :items="table_details"
                    :items-per-page="20"
                    :search="search"
                    class="table">

                    <template v-slot:item="{ item }">
                        <tr>
                            <td width="20%" class="text-xs-left">{{ item.date_f }}</td>
                            <td width="35%" class="text-xs-left">{{ item.type }}</td>
                            <td width="15%" class="text-xs-center">{{ item.point }}</td>
                            <td width="15%" class="text-xs-center">{{ item.cumulative_sum }}</td>
                            <td width="15%" class="text-xs-center">
                                <v-icon @click="view_datail(item)">view_list</v-icon>
                            </td>
                        </tr>    
                    </template>                
                </v-data-table>
        </v-container>


        <v-dialog v-model="dialog_s"  width="600" >
            <v-card>
                <v-card-title class="headline">Date : {{date_f}} </v-card-title> 
                <br>
                  <v-card-text>
                        <div>                 
                            <table width="100%">
                                    <tr>            
                                    <td width="20%" ><h4></h4></td>
                                    <td width="60%" ><h4>Item name</h4></td>
                                    <td width="10%" ><h4 class="font-weight-regular"><center>Qty</center></h4></td>
                                    <td width="10%" ><h4 class="font-weight-light"></h4></td>     
                                </tr>
                                <tr  v-for=" n in order_hist" :key = "n" >            
                                    <td width="20%" >

                                        <v-img :src="url + n.image" contain  height="100"  max-width="100"  >
                                        </v-img>

                                    </td>
                                    <td width="60%" ><h4 class="font-weight-light">{{n.name}}</h4></td>
                                    <td width="10%" ><h4 class="font-weight-regular"><center>{{n.qty}}</center></h4></td>
                                </tr>
                            </table>               
                        </div>           
                </v-card-text>
                <br>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_c" width="400" >
            <v-card>
                <v-card-title class="headline">Credit Details :</v-card-title> 
                    <v-card-text>
                        <v-container class="ma-5">                   
                            <h4>Date : {{date_f}}</h4>
                            <h4>Credits Added By : {{type}}</h4> 
                            <h4>Point : {{point}}</h4>
                        </v-container>
                    </v-card-text>

                <br>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "main_history_comp",
    components:{

    },
  data() {
    return {
            headers:[ 
              { text: 'Date', value: 'date_f' },
              { text: 'Order Number', value: 'type' },
              { text: 'Credits Addrd/Used', value: 'point' , sortable: false},
              { text: 'Remaining Credit Balance', value: 'cumulative_sum' , sortable: false},
              { text: 'Details', value: 'actions',  sortable: false },
            ],
            table_details :[], 
            headers1:[ 
              { text: 'Order Date', value: 'date' },
              { text: 'Order No', value: 'id' },
              { text: 'Week Range', value: 'rang_week' },
            ],    
            order_hist :[],
            dialog_s : false , 
            dialog_c : false ,             
            order_no : '',
            order_date : '',
            c_id :'',
            type :'',
            point : '',
            date_f : '',
            date : '',
            url : "https://ws.plantifulbkk.shop/images/" ,
        }
    },
    created () {
      console.log("CREATE")
      this.read_data()
    },
    methods :{
        read_data(){
            axios.get("https://ws.plantifulbkk.shop/get_order_cus_hist1.php",{
                        params: {
                            scode: this.$store.getters.get_user_code
                        }
                    }).then(result=>{
                this.table_details =  result.data
                console.log(result.data)
            })
        },
        read_data_h(){

            axios.get("https://ws.plantifulbkk.shop/get_hist_detail.php",{
                        params: {
                            scode: this.$store.getters.get_user_code ,
                            stype: this.date
                        }
                    }).then(result=>{
                this.order_hist =  result.data
                console.log(result.data)
            })
        },
        view_datail( item ){

            this.c_id = item.c_id
            this.type = item.type
            this.point = item.point
            this.date_f = item.date_f
            this.date = item.date
            if ( item.seq == 1 ){
                this.dialog_c = true
            }else{
                this.read_data_h() 
                this.dialog_s = true  
            }

        },
    }
}
</script>

<style>

</style>