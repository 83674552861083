<template>
    <div class="text-center" >
      <br><br><br>
        <p>Register Page</p>
        <v-layout row>
          <v-flex xs6 sm4 offset-xs3 offset-sm4>

                <v-container>

                    <v-btn block color="success" @click="login_with_line()" >      
                        <v-icon left  >
                        mdi-chat-outline
                        </v-icon>
                        <h2 class="a">Sign up with Line</h2>
                    </v-btn>

                </v-container>
                OR
                <v-container>

                <v-btn block dark @click="login_with_email()" >      
                    <v-icon left dark >
                    mdi-email-outline
                    </v-icon>
                    <h2 class="a">Sign up with Email</h2>
                </v-btn>
                  
                </v-container>
          </v-flex>
        </v-layout>
    </div>
</template>

<script>
import axios from "axios"
import liff from "@line/liff";

export default {
    name: "select_reg_comp",
    components:{

    },
  data() {
    return {
        pkg:'', 
        username: '',
        password: '',
        event_id: '',
        value: String,
    }
  },
  mounted() {
    let uri = window.location.search.substring(); 
    let params = new URLSearchParams(uri);
    this.pkg = params.get("pkg");
    console.log(  this.pkg )
    //this.$store.dispatch('sel_pkg_ac', this.pkg )

  },

  methods :{


    keep_session (){

      this.$session.start()
      this.$session.set('vue_pkg', this.pkg  )
      this.$store.dispatch('set_user_ac_code', '')

    },

    initializeLiff: function () {

        liff.init({
        liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
        })
        .then(() => {
          // start to use LIFF's api
          if (liff.isLoggedIn()) {

              liff.getProfile()
              .then(profile => {

                this.name =  profile.displayName 
                this.uid = profile.userId

                

                this.$router.push('/')

              })
              .catch((err) => {
                console.log('error', err)
              })            
              

              //me.$router.push({ name: 'login' })
            }else{

            liff.login();

            }
        })
        .catch((err) => {
          console.log(err);
        });

        },

    login_with_email(){
      this.$router.push('/register')
    },
    login_with_line(){
      
            this.event_id = this.make_event_id(5)
            //var type_of_action 

            axios.get("https://ws.plantifulbkk.shop/post_event_log_g.php",{
                        params: {
                          scode: this.username ,
                          event_id : this.event_id ,
                          SESSION : '',
                          log : 'start..login.by.line' ,
                        }
                    }).then(result=>{

                      console.log(result)
            }) 

          //type_of_action = this.$store.getters.get_type_of_action  

          //window.location.href = "https://api.plantifulbkk.shop/login_uselib.php?type="+type_of_action+"&event_id="+this.event_id  
          this.keep_session()
          this.initializeLiff();
  
    },

    make_event_id(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
    }
    return Date.now()+result;
    },

  },
}
</script>

<style>

p {
  font-size: 1.2em;
}
@media (min-width: 48rem) {
  p {
    font-size: 2em;
  }
}

h2.a {
  font-size: 0.8em;
  color: white;
}
@media (min-width: 48rem) {
  h2.a {
    font-size: 1.2em;
    color: white;
  }
}

</style>