<template>
    <div>
        <mainpage/>
    </div>
</template>

<script>
import mainpage from "@/components/main_order.vue";

export default {


  name: 'main_view',
  components: {
    mainpage,
  }
}
</script>
