<template>
    <div>
        <customerinfo/>
    </div>
</template>

<script>
import customerinfo from "@/components/customer_info.vue";

export default {


  name: 'customer_info_view',
  components: {
    customerinfo,
  }
}
</script>