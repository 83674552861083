<template>
    <div>
      <calendarcomp/>
    </div>
</template>

<script>

import calendarcomp from "@/components/calendar.vue";

export default {

  name: 'calendar_view',
  components: {
    calendarcomp,
  }
}
</script>