<template>
    <div>
        <mainhistory/>
    </div>
</template>

<script>
import mainhistory from "@/components/main_history.vue";

export default {


  name: 'history_view',
  components: {
    mainhistory,
  }
}
</script>