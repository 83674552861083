<template>
    <div>
        <confirmpage/>
    </div>
</template>

<script>
import confirmpage from "@/components/confirm.vue";

export default {
    name: 'confirm_page_view',
    components: {
    confirmpage,
  },
  
}
</script>

<style>

</style>